<template>
    <!-- <ConfirmDialog :show="showDialog"
    :okButton="da"
    :noButton="nu"
    :cancel="cancel"
    :confirm="confirm"
    title="Atenție!"
    description="Doriți ștergerea activității?" /> -->

    <div id="user-admin" class="transition">
         
        <h4><fa class="me-2 " icon="user" size="1x"/><b>ADMINISTRARE UTILIZATORI</b></h4>
        
        <div class="row">
            <div id="select-list" class="col-lg-3 col-md-2 col-sm-12">
                <select class="form-select" size="2" id="user-select">
                    <option @click="showUserInfo(uti.id,uti.acces,uti.cnp,uti.localitate,uti.adresa,uti.telefon,uti.email,uti.cod_inreg)" v-for="uti in utiList" :key="uti.id" :value="uti.id">{{uti.nume_prenume}}</option>
                </select>
            
            </div>
            <div v-if="rolPicked" style="text-align: -webkit-center;" class="col-lg-9 col-md-10 col-sm-12">
                <h3>Rol utilizator</h3>
                <hr class="mx-0" style="width:200px;margin-top: 3px;">
                <div style="place-content: center;" class="d-flex mt-4">
                    <div v-if="(loggedUserId == idUtiSel && loggedUserAccess == 1) || loggedUserAccess == 1" class="form-check me-4">
                        <input @click="changeRole(1)" class="form-check-input" type="radio" id="admin" value="1" v-model="rolPicked" />
                        <label class="form-check-label fw-bold" for="admin">Administrator</label>
                    </div>
                    <div v-if="loggedUserAccess == 1 && loggedUserId != idUtiSel" class="d-flex">
                        <div class="form-check me-4">
                            <input @click="changeRole(2)" class="form-check-input" type="radio" id="partener" value="2" v-model="rolPicked" />
                            <label class="form-check-label fw-bold" for="partener">Partener</label>
                        </div>
    
                        <div class="form-check me-4">
                            <input @click="changeRole(3)" class="form-check-input" type="radio" id="ofertant-loc-munca" value="3" v-model="rolPicked" />
                            <label class="form-check-label fw-bold" for="ofertant-loc-munca">Ofertant loc muncă</label>
                        </div>
                        <div class="form-check mb-3">
                            <input @click="changeRole(4)" class="form-check-input" type="radio" id="grup-tinta" value="4" v-model="rolPicked" />
                            <label class="form-check-label fw-bold" for="grup-tinta">Grup țintă</label>
                        </div>
                    </div>
                    <hr>
                </div>
                
                
                <div v-if="rolPicked != 1" style="place-content: center;" class="mt-1">
                    <hr>
                    <div style="text-align: left;">
                        <h3>Informații utilizator</h3>
                    <hr style="width:200px;">
                        <b>CNP:</b> {{cnp}}<br>
                        <b>Localitate:</b> {{localitate}}<br>
                        <b>Adresa:</b> {{adresa}}<br>
                    
                        <b>Telefon:</b> {{telefon}}<br>
                        <b>E-mail:</b> {{email}}<br>
                        <b>Cod înregistrare:</b> {{codInreg}}

                    </div>
                   
                    <hr>
                </div>
               
                <div v-if="rolPicked != 1">
                    <div style="text-align: -webkit-center;" class="mb-3">
                        <h3>Fișiere utilizator</h3>
                        <hr class="mx-0 my-0" style="width:200px;">
                    
                    </div>
                    
        
                    <div id="user-files" class="d-flex row">
                        <a @click.prevent="downloadUserFile(file)" class="col-sm-12 mt-2" v-for="file in fileList" :key="file" >
                            <label class="d-flex flex-column">
                                <fa class="me-2" icon="file" color="brown" size="3x"/>
                                {{file}}
                            </label>
                        </a>
        
                        
                    </div>
                </div>
                
            </div>
         
        </div>
        
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import axios from "axios"
    import php_url from "../../main.js"

    import ConfirmDialog from '../dialogs/ConfirmDialog'


    export default defineComponent({
        components: { ConfirmDialog },
        inheritAttrs: false,
        name: 'UtilizatoriAdmin',
        data() {
            return {
                loggedUserId: "",
                loggedUserAccess: "",
                utiList: "",
                idUtiSel: "",
                rolPicked: "",
                cnp: "",
                localitate: "",
                adresa: "",
                telefon: "",
                email: "",
                codInreg: "",
                showDialog: false,
                da: "DA",
                nu: "NU",
                idActivit: "",
                fileList: "",
            }
        },
        methods: {
            getUtiList() {
                axios.post(php_url.php_url,JSON.stringify({action:"getUtiList"})).then((res) => {
                    this.utiList = res.data
                })
            },
            showUserInfo(idUti,acces,cnp,localitate,adresa,telefon,email,cod_inreg) {
                // console.log(acces);
                this.fileList = ""
                this.idUtiSel = idUti
                this.rolPicked = acces
                this.cnp = cnp
                this.localitate = localitate
                this.adresa = adresa
                this.telefon = telefon
                this.email = email
                this.codInreg = cod_inreg

                this.getUserFiles()
            },
            changeRole(role) {
                axios.post(php_url.php_url,JSON.stringify({action:"changeRole",id_uti:this.idUtiSel,role:role})).then((res) => {
                   
                  if(res.data == 1) {
                    this.getUtiList()
                  }
                })
            },
            ckAccess() {
                axios.post(php_url.php_url,JSON.stringify({action:"ckAccess"})).then((res) => {
                        this.loggedUserAccess = res.data.access
                        this.loggedUserId = res.data.idCurrUti
                        
                    })
            },
            callDialog(activitId) {
                this.idActivit = activitId
                this.showDialog = true
            },

            cancel() {
                // console.log('cancel');
                this.showDialog = false;
            },
            confirm() {
                
                this.showDialog = false;  
            },
            getUserFiles() {
               
                axios.post(php_url.php_url,JSON.stringify({action:"getUserFiles",user_id: this.idUtiSel})).then((res) => {
                    this.fileList = res.data.files
                })

              
            },
            downloadUserFile(filename) {
              
                let requestObject = JSON.stringify({action: "downloadUserFile",user_id: this.idUtiSel,nume_fisier: filename});

                let request = async () => {
                    await fetch(php_url.php_url, {
                        method: "POST",
                        mode: 'cors',
                        credentials: 'include',
                        headers: {
                            // 'Accept': 'application/json',
                            // 'Content-Type': 'application/json'
                            // "Content-type": "application/x-www-form-urlencoded"
                        },
                        body: requestObject
                    })
                        .then(response => response.blob())
                        .then(blob => saveAs(blob, filename))
                };

                request();
            },
        },
        mounted() {
            this.getUtiList()
            this.ckAccess()
        },
        
        
    })
</script>

<style>
    #user-admin {
        /* border: 2px solid #395e8d; */
        border-radius: 5px;
        margin-bottom: 3px;
        height: calc(100vh - 197px);
        overflow-y: auto;
        padding: 5px 20px;

        font-family: sans-serif;
    }

    #user-admin #user-select {
        height: calc(100vh - 262px);
        font-size: 18px;
    }

    #user-admin div#select-list {
        border-right: 2px solid gray;
        padding-right: 21px;
    }

    #user-admin #select-list select {
        cursor: pointer;
        font-size: 18px;
    }

    #user-admin #user-files  {
        justify-content: center;
        column-gap: 15px;
    }

    #user-admin #user-files a {
        text-decoration: none;
    }

    #user-admin #user-files a label {
        cursor: pointer;
    }


    #user-admin hr {
        margin-top: 3px;
    }

    #user-admin img{
        width: 15%;
        border-radius: 22px;
        cursor: pointer;
        display: flex;
        float: left;
        padding-right: 8px;
    }

    #user-admin p {
        text-align: justify;
        
        line-height: 26px;
        font-size: 19px;
        margin-top: 10px;
    }

    #user-admin h4 {
        text-align: left;
    }
        
    @media only screen and (max-width: 991px) {
        div#user-admin {
        margin-left: 7px;
        }
    }
</style>