<template>
  <ContentPopUp :show="showDialog"
    :okButton="da"
    :noButton="nu"
    :cancel="cancel"
    :confirm="confirm"
    title="Declarație privind prelucrarea datelor cu caracter personal!"
    :description="ContinutGdpr" />

  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
  
       <div class="" id="inreg-uti-content">
       
          <h4><fa class="me-2 " icon="arrow-right" size="1x"/><b>ÎNREGISTRARE UTILIZATOR NOU</b></h4>
          <hr>
        <div  id="formular">
            
          <form class="d-flex flex-column py-3" @submit.prevent="onSubmit">
        
            <h4><b>FORMULAR ÎNREGISTRARE</b></h4>
            <hr class="m-1 text-light">
            
            <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
              {{Message}}
            </div>
            <input type="text" placeholder="Nume și prenume" class="form-control align-self-center " v-model="NumeInreg">
            <input type="text" placeholder="CNP" class="form-control align-self-center " v-model="CnpInreg">
            <input type="text" placeholder="Localitate" class="form-control align-self-center " v-model="LocalitateInreg">
            <input type="text" placeholder="Adresa" class="form-control align-self-center " v-model="AdresaInreg">
            <input type="text" placeholder="Telefon" class="form-control align-self-center " v-model="TelefonInreg">
            <input type="text" placeholder="Email" class="form-control align-self-center " v-model="EmailInreg">
            <input type="text" placeholder="Utilizator" class="form-control align-self-center " v-model="UtiInreg">
            <input type="password" placeholder="Parolă acces" class="form-control align-self-center" :class="{'bg-danger': bgDanger}" v-model="ParolaAccesInreg">
            <input type="password" placeholder="Repetă parolă acces" class="form-control align-self-center"  :class="{'bg-danger': bgDanger}" v-model="RparolaAccesInreg">
            <label style="place-self:center;" class="mx-auto text-danger fw-bold mt-2"> Cod acces înregistrare
              <input type="text" placeholder="" class="form-control align-self-center mx-auto text-center" v-model="CodAccesInreg">
            </label>

            
            <div style="align-self: center;align-items: center;" class="d-flex ">
              <input @click="callDialog" style="height: 21px;width: 0;" type="checkbox" class="ms-1" v-model="GdprCk"/>
            
              <label @click="callDialog" style="cursor:pointer;" class="text-light fw-bold" >
               Acord utilizare date cu caracter personal
              </label>
            </div>
            
            <hr class="m-1 text-light">
            <div style="column-gap: 14%;place-content: center;" class="d-flex mx-auto">
              <button class="btn btn-success align-self-center my-2" id="btn-inreg-uti" @click="InregistrareUti">Înregistrare</button>
              <button class="btn btn-danger align-self-center my-2" id="btn-reset-form" @click="ResetFormular">Resetare</button>
            </div>

        </form>
      </div>
          
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'

import ContentPopUp from '../components/dialogs/ContentPopUp'

import axios from "axios"
import php_url from "../main.js"

import { load } from 'recaptcha-v3'

export default defineComponent({
  components: {HeaderWebsite,FooterWebsite,ContentPopUp},
  name: 'Prezentare',
  data() {
    return {
      showDialog: false,
      da: "Confirm",
      nu: "Refuz",
      GdprCk: false,
      ContinutGdpr: "<span style='text-align:justify;'>Îmi dau acordul cu privire la utilizarea și prelucrarea datelor personale (conform prevederilor Regulamentului privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date) în cadrul Proiectului POCU/827/5/2/140679 - „Măsuri integrate de combatere a marginalizării și excluziunii sociale în comuna Sihlea, judetul Vrancea”</span>",
      NumeInreg: "",
      CnpInreg: "",
      LocalitateInreg: "",
      AdresaInreg: "",
      TelefonInreg: "",
      EmailInreg: "",
      UtiInreg: "",
      ParolaAccesInreg: "",
      RparolaAccesInreg: "",
      CodAccesInreg: "",
      Message: "",
      bgDanger: false,
      reqSuccess: false,
      token: ""
    }
  },
  methods: {
    InregistrareUti() { 

      if(!this.NumeInreg || !this.LocalitateInreg || !this.AdresaInreg || !this.TelefonInreg || !this.EmailInreg || !this.UtiInreg || !this.ParolaAccesInreg || !this.RparolaAccesInreg || !this.CodAccesInreg) {
        this.Message = "Toate câmpurile sunt obligatorii!"
      } else {
        if(this.ParolaAccesInreg != this.RparolaAccesInreg) {
          this.Message = "Parolele nu sunt identice!"
          this.bgDanger = true
        } else if (this.GdprCk !== true) {
          this.Message = "E necesar acordul pentru prelucrarea datelor cu caracter personal!"
          this.reqSuccess = false
        } else {
          this.Message = ""
          this.bgDanger = false

          this.recaptchaToken().then(() => {
            let data = {action:"RegisterUser",nume_uti: this.NumeInreg,cnp_uti: this.CnpInreg,loc_uti: this.LocalitateInreg,adresa_uti: this.AdresaInreg,telefon_uti: this.TelefonInreg,email_uti: this.EmailInreg,uti_inreg: this.UtiInreg,parola_uti: this.ParolaAccesInreg,cod_acces_uti: this.CodAccesInreg,token: this.token,gdpr_ck: this.GdprCk}

            axios.post(php_url.php_url,JSON.stringify(data)).then((res) => {

              if(res.data.code == 1) {
                this.reqSuccess = true
                this.Message = "Utilizator înregistrat cu succes!"

                setTimeout(function() {
                  this.$router.push('/login')
                }.bind(this), 2000);
              } else if(res.data.code == 2) {
                this.Message = "Codul de acces e deja folosit!"
              } else if(res.data.code == 3) {
                this.Message = "Numele de utilizator e deja folosit!"
              } else if(res.data.code == 4) {
                this.Message = "Codul de acces nu există în baza de date!"
              } else {
                this.Message = "Eroare necunoscută!"
              }


              // console.log(res.data.code)
              
            })
          })

 
        }
       
      }
      
    },
    callDialog(anuntId) {
        this.showDialog = true
    },
    cancel() {
        // console.log('cancel');
        this.showDialog = false
        this.GdprCk = false
    },
    confirm() {
        this.showDialog = false;
        this.GdprCk = true
    },
    async recaptchaToken() {
      const recaptcha =  await load('6LcvvBMjAAAAAA3airCHauoCe3TGQhvrdmD-n_oG',{autoHideBadge: false})
      recaptcha.showBadge()
      let token = await recaptcha.execute('login')

      this.token = token

      setTimeout(function() {
        recaptcha.hideBadge()
      }.bind(this),3000)

    },
    ResetFormular() {
      this.NumeInreg = "",
      this.CnpInreg = "",
      this.LocalitateInreg = "",
      this.AdresaInreg = "",
      this.TelefonInreg = "",
      this.UtiInreg = "",
      this.ParolaAccesInreg = "",
      this.RparolaAccesInreg = "",
      this.CodAccesInreg = "",
      this.Message = ""
      this.bgDanger = false,
      this.reqSuccess = false
    }
    
  },
  mounted() {
   
  }
    
})
</script>

<style>

  .BkgRedClass {
    background-color: red;
  }

  #inreg-uti-content {
    border-radius: 5px;
    height: calc(100vh - 172px);
    padding: 14px;
    font-family: sans-serif;
    overflow-y: auto;
  }

  #inreg-uti-content h4 {
    text-align: left;
  }

  #inreg-uti-content #formular {
    border: solid 2px #155185;
    border-radius: 5px;
    width: 100%;
    display: grid;
    box-shadow: 5px 6px 7px;
    background-color: #155185;
    max-width: 550px;
    margin: 0 auto;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    top: 76%;
    transform: translateY(-86%);
    position: relative;
    
  }

  #inreg-uti-content #formular input {
      margin-bottom: 7px;
      margin-top: 7px;
      border-radius: 5px;
      height: 32px;
      font-size: 17px;
      padding: 7px;
      width: 100%;
      max-width: 400px;
      min-width: 40px;
  }

  #inreg-uti-content #formular #loginBtn { 
    width:100px;
  }

  #inreg-uti-content #formular h4 {
    text-align: center;
    letter-spacing: 4px;
    color: #ffffff;
    text-shadow: 3px 4px 5px #222222;
  }

  #inreg-uti-content #inregistrare-uti {
    color: #bbb744;
  }

  #inreg-uti-content #inregistrare-uti:hover {
    color: #bbb7448a;
  }

  @media only screen and (max-width: 515px) {
    #inreg-uti-content #formular input {
      width: 76%;
    }

  }
  

</style>