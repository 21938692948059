<template>
   <nav  class="navbar navbar-expand-lg navbar-dark bg-dark" id="website-navbar">
        <div class="container">
            <div id="header-imgs">
                <img  src="../assets/sigla_UE.png" title="P.O.C.U. SIHLEA" class="py-2">
                <img  src="../assets/sigla_guvernul_romaniei.png" title="P.O.C.U. SIHLEA" class="py-2">
                <img  src="../assets/sigla_instr_structurale.png" title="P.O.C.U. SIHLEA" class="py-2">
            </div>
            
            <a style="" class="navbar-brand" href="">P.O.C.U. SIHLEA<br><span style="font-size:18px">140679</span></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse transitionFast" id="navbarTogglerDemo02">
                <ul  class="navbar-nav mx-auto">
                
                    <li class="nav-item btn ms-1" >
                        <router-link to="prezentare"  class="nav-link text-white"> <fa icon="house-chimney" size="lg" color="white"/> Prezentare </router-link>
                    </li>
               
                    <li class="nav-item btn ms-1" >
                        <router-link to="anunturi"  class="nav-link text-white"> <fa icon="bullhorn" size="lg" color="white"/> Anunțuri </router-link>
                    </li>

                    <li class="nav-item btn ms-1" >
                        <router-link to="activitati"  class="nav-link text-white"> <fa icon="arrows-spin" size="lg" color="white"/> Activități </router-link>
                    </li>

                    <li class="nav-item btn ms-1" >
                        <router-link to="proiecte"  class="nav-link text-white"> <fa icon="diagram-project" size="lg" color="white"/> Proiecte </router-link>
                    </li>

                    <li class="nav-item btn ms-1" >
                        <router-link to="parteneri"  class="nav-link text-white"> <fa icon="handshake" size="lg" color="white"/> Parteneri </router-link>
                    </li>

                    <li v-cloak v-if="[1,3].includes(access)" class="nav-item btn ms-1" >
                        <router-link to="locuri-munca"  class="nav-link text-white "> <fa icon="laptop-file" size="lg" color="white"/> Locuri muncă </router-link>
                    </li>

                    <li class="nav-item btn ms-1" >
                        <router-link to="consiliere"  class="nav-link text-white"> <fa icon="person-shelter" size="lg" color="white"/> Consiliere și Asistență </router-link>
                    </li>
                   
                    <li class="nav-item btn ms-1" >
                        <router-link to="contact"  class="nav-link text-white"> <fa icon="address-book" size="lg" color="white"/> Contact </router-link>
                    </li>
                </ul>
            </div> 

            <router-link v-if="allAccess.includes(access)" id="panel" to="panel" title="PANOU ADMINISTRARE" class="float-end text-decoration-none header-buttons me-3 transitionFast">
                <fa icon="screwdriver-wrench" color="white" size="2x"/>      
                <span>PANOU</span>
            </router-link>  
           
            <router-link v-if="levelAccessLogin" id="panel-login" to="login" title="LOGIN PANEL" class="float-end text-decoration-none header-buttons transitionFast">
                <fa icon="right-to-bracket" color="white" size="2x"/>      
                <span>LOGIN</span>
            </router-link>
        
        
            <router-link v-if="levelAccessLogout" @click="logout()" id="logout" to="/" title="LOG OUT" class="float-end text-decoration-none header-buttons transition">
                <fa icon="right-from-bracket" color="white" size="2x"/>      
                <span>LOGOUT</span>
            </router-link>
       
            
        </div>
    </nav> 
</template>

<script>
import { defineComponent } from 'vue';

import axios from "axios"
import php_url from "../main.js"

export default defineComponent({
  name: 'HeaderWebsite',
  props: [''],
  data () {
    return {
        allAccess: [1,2,3,4],
        access: "",
        levelAccessLogin: false,
        levelAccessLogout: false,
    }
  },
    
  methods: {
        toggleAccess(access) {
            this.access = Number(access)
            if(this.allAccess.includes(access)) {
                this.levelAccessLogin = false
                this.levelAccessLogout = true
            } else {
                this.levelAccessLogin = true
                this.levelAccessLogout = false
            }
        },
           
        ckAccess() {
            axios.post(php_url.php_url,JSON.stringify({action:"ckAccess"})).then((res) => {
                    this.toggleAccess(Number(res.data.access))
                    
                    this.access = Number(res.data.access)
                    // console.log(this.access);
                })
            },
        logout() {
                axios.post(php_url.php_url,JSON.stringify({action:"logout"})).then((res) => {
                    this.toggleAccess(res.data.access)
                   
                })
            }
    },

    mounted() {
        this.ckAccess()
    }

})


</script>

<style>
    nav#website-navbar{
        background-image: url('../assets/banner.jpg')!important;
    /* background-image: linear-gradient(to right, #007eff , #20ab98)!important; */
        height: 115px;
    }

    #website-navbar .container {
        max-width: 1800px;
    }
    #website-navbar #header-imgs {
        border-radius: 5px;
        background: linear-gradient(0deg, #e7e7e7, transparent);
    }

    #website-navbar #header-imgs img {
        margin-right:5px;
        margin-left:10px;
        width: 69px;
    }

    #website-navbar .header-buttons {
        display: grid;
        place-items: center;
    }

     #website-navbar a.header-buttons span,#website-navbar a.header-buttons{
        color: white;
        font-size:14px;
        padding-top:5px;
     }
     
     #website-navbar a.header-buttons span:hover{
        color: rgb(111, 175, 228);
     }

    #website-navbar svg{
        padding-right: 2px;
    }

    #navbarTogglerDemo02 {
        z-index: 10;
    }

    #navbarTogglerDemo02 ul li{
        display: grid;
        align-content: center;
        height:36px;
        background: linear-gradient(45deg, black, transparent);
        font-size:13px;
    }

    nav#website-navbar a.navbar-brand {
        text-shadow: 8px 9px 4px black;
        font-size: 25px;
        margin-left: 11px;   
    }

    @media only screen and (max-width: 991px) {
        nav#website-navbar ul li {
            background: black!important;
            border-radius:5px;
            margin-top:2px;
        }
        nav#website-navbar img{
            margin-top: -6px;
            padding: 0!important;
            margin-bottom: 10px;
        }

        
    }

    @media only screen and (max-width: 515px) {
        nav#website-navbar a.navbar-brand {
            font-size: 17px;
            margin-left: 0;
        }

        #website-navbar #header-imgs img {
            width: 49px;
        }
    }
</style>