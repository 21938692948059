<template>
  <footer>

    <!-- <ul class="nav justify-content-center">
      <li class="nav-item " v-for="link in navLinks" :key="link.text">
        <router-link :to="link.link" class="nav-link text-white">{{link.text}}</router-link>
      </li>
    </ul> -->
    <hr>
    <span>
      {{ new Date().getFullYear() }} &#169; <strong>P.O.C.U. SIHLEA</strong>
    </span>
    
  </footer>
</template>

<script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'FooterWebsite',
  props: [''],
  data: () => ({
      
    }),
    methods: {
      
    }
 
})
</script>

<style>
 footer {
   background-image: url('../assets/banner.jpg')!important;
  /* background-image: linear-gradient(to right, #007eff , #20ab98); */

    padding-bottom: 18px;
    padding-top: 4px;
 }

 footer span{
  color: white
 }

 footer li a{
  color: white;
    text-shadow: 2px 4px 3px #1801019c;
 }

 footer li a:hover{
  color: rgb(230, 226, 31)!important;
 }

 footer hr {
  width: 20%;
  margin: 8px auto;
 }

 
</style>