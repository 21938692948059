<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
  
       <div class="" id="login-content">
       
          <h4><fa class="me-2 " icon="arrow-right" size="1x"/><b>PANEL LOGIN</b></h4>
          <hr>
        <div  id="formular">
            
          <form class="d-flex flex-column py-3" @submit.prevent="onSubmit">
        
            <h3><b>AUTENTIFICARE</b></h3>
            <hr class="my-1 text-light">
            <br>
            <input type="text" placeholder="User" class="form-control align-self-center text-center" v-model="User">
            <input type="password" placeholder="Parolă" class="form-control align-self-center text-center" v-model="Parola">
            
            <div v-if="Error == '0'" style="height: 20px;width:300px;" class="alert alert-danger my-3 d-flex mx-auto align-items-center justify-content-center" role="alert">
              Credențialele nu sunt corecte!
            </div>
            <button class="btn btn-success align-self-center my-2" id="loginBtn" @click="Login">Login</button>
            <hr class="text-light">
          
            <router-link id="inregistrare-uti" to="inregistrare-utilizator" class="text-decoration-none" title="ÎNREGISTRARE UTILIZATOR NOU">
              <fa class="me-2 " icon="user" size="1x"/>
              <span class=" fw-bold">Înregistrare utilizator nou</span>
            </router-link>
           

        </form>
      </div>
          
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'

import axios from "axios"
axios.defaults.withCredentials = true;

import php_url from "../main.js"

import { load } from 'recaptcha-v3'

export default defineComponent({
  components: {HeaderWebsite,FooterWebsite},
  name: 'Prezentare',
  data() {
    return {
      User: "",
      Parola: "",
      Error: "",
      levelAccess: "",
      token: ""
    }
  },
  methods: {
     async Login() {
      //  // (optional) Wait until recaptcha has been loaded.
      //  await this.$recaptchaLoaded()

      // // Execute reCAPTCHA with action "login".
      // const token = await this.$recaptcha('login')

      this.recaptchaToken().then(() => {
        axios.post(php_url.php_url,JSON.stringify({action:"LoginPanel",username: this.User,parola: this.Parola,token: this.token})).then((res) => {
            
            if(res.data.access == 0) {
              this.$router.push('login');
            } else {
              this.$router.push('/');
            }
              
            this.Error = res.data.access
          })
      })

    },
    async recaptchaToken() {
      const recaptcha =  await load('6LcvvBMjAAAAAA3airCHauoCe3TGQhvrdmD-n_oG',{autoHideBadge: false})
      recaptcha.showBadge()
      let token = await recaptcha.execute('login')

      this.token = token

      setTimeout(function() {
        recaptcha.hideBadge()
      }.bind(this),3000)

    },

  },
  mounted() {
   
  }
    
})
</script>

<style>

  #login-content {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    padding: 20px;
    font-family: sans-serif;
  }


  /* #login-content hr {
    
    margin-top: 8px;
  } */

  #login-content h4 {
    text-align: left;
  }

  #login-content #formular {
    border: solid 2px #155185;
    border-radius: 5px;
    width: 100%;
    display: grid;
    box-shadow: 5px 6px 7px;
    background-color: #155185;
    max-width: 450px;
    margin: 0 auto;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    top: 50%;
    transform: translateY(-86%);
    position: relative;
    
  }



  #login-content #formular input {
      margin-bottom: 8px;
      margin-top: 8px;
      border-radius: 5px;
      height: 40px;
      font-size: 17px;
      padding: 7px;
      width:300px;
  }

  #login-content #formular #loginBtn { 
    width:100px;
  }

  #news-content #formular-cv textarea {
    border-radius: 5px;
    padding: 7px;
  }

  #login-content #formular h3 {
    letter-spacing: 4px;
    color: #ffffff;
    text-shadow: 3px 4px 5px #222222;
  }

  #login-content #inregistrare-uti {
    color: #bbb744;
  }

  #login-content #inregistrare-uti:hover {
    color: #bbb7448a;
  }
    
  /* @media only screen and (max-width: 991px) {
    div#news-content {
      margin: 0 auto;
      border: unset;
    }

    #news-content #sectiune-cv {
      height: unset;
    }
    

    #news-content #formular-cv {
      margin-left: 0;
    }

    #news-content {
    height: unset;
    }

    #news-content #anunturi {
      padding-right: 5px;
      height: unset;
      overflow-y: hidden;
    }

    body {
      overflow-y: auto;
    }
  } */

</style>