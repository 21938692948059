<template>

    <div id="adauga-activit-admin" class="transition">
        
    
        <h4><fa class="me-2 " icon="plus" color="green" size="1x"/><b>ADAUGĂ ACTIVITATE</b></h4>
    
        <hr>
    
        <div class="d-flex flex-column mx-auto w-75">
            <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
                {{Message}}
            </div>
            <label class="my-3 text-start"><h6 class="mb-1 text-start"><b>Imagine activitate</b></h6>
                <input @change="handleFileUpload()" ref="file" class="my-1 form-control" type="file" id="upload-image">
            </label>
            <label class="my-1 text-center"><h6 class="mb-1 text-start" ><b>Titlu activitate</b></h6>
                <input style="height: 31px;" class="rounded-1 border border-2 border-primary w-100 p-2" type="text" v-model="titluActivit" />
            </label>
            
            <label  class="my-2 text-center"><h6 class="mb-1 text-start" ><b>Conținut</b></h6>
                <textarea class="rounded-1 border border-2 border-primary w-100 p-2" rows="15"  v-model="continutActivit"></textarea>
            </label>
            <button @click="uploadActivit()" class="btn btn-success mx-auto my-3 ">Salvează</button>
        </div>
        
    </div>

</template>

<script>
    import { defineComponent } from 'vue';
    import axios from "axios"
    import php_url from "../../main.js"

    export default defineComponent({
        name: 'AdaugaActivitate',
        props: ['idActivit'],
        data() {
            return {
                titluActivit: "",
                continutActivit: "",
                file: "",
                Message: "",
                reqSuccess: false,
            }
        },
        methods: {
            uploadActivit() {
                if(!this.titluActivit) {
                    this.Message = "Completați titlul activității!"
                } else if(!this.continutActivit) {
                    this.Message = "Completați conținutul activității!"
                } else {
                    let formData = new FormData();

                    formData.append('file',this.file)
                    formData.append('action',"uploadActivitate")
                    formData.append('titlu_activit',this.titluActivit)
                    formData.append('continut_activit',this.continutActivit)
                    formData.append('id_activit',this.idActivit)

                    // let data = {action:"uploadAnunt",titlu_anunt: this.titluAnunt,continut_anunt: this.continutAnunt}

                    axios({
                        url: php_url.php_url,
                        method: 'post',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Accept': 'application/json'
                        }
                    }).then((res) => {
                        // console.log(res.data);

                        if(res.data == 1) {
                            this.reqSuccess = true
                            this.Message = "Anunț salvat cu succes!"
                        } else if(res.data == 0) {
                            this.Message = "Fișierul atașat nu este imagine!"
                        } else if(res.data == 2) {
                            this.Message = "Fișierul atașat există în baza de date!"
                        } else {
                            this.Message = "Eroare necunoscută!"
                        }
                    })
                }
            },
            handleFileUpload() {
                this.file = this.$refs.file.files[0]
            },
            getActivit() {
             
                axios.post(php_url.php_url,JSON.stringify({action:"getActivitate",id_activit:this.idActivit})).then((res) => {
                  
                    this.titluActivit = res.data.titlu
                    this.continutActivit = res.data.continut

                    })
            },
        },
        mounted() {
           this.getActivit()
        }
        
    })
</script>

<style>
    #adauga-activit-admin {
        /* border: 2px solid #395e8d; */
        border-radius: 5px;
        margin-bottom: 3px;
        height: calc(100vh - 204px);
        overflow-y: auto;
        padding-top: 10px;
        font-family: sans-serif;
    }


    #adauga-activit-admin hr {
        width:100%;
        margin-top: 8px;
    }

    #adauga-activit-admin img{
        cursor: pointer;
        width: 40%;
        border-radius: 22px;
        display: flex;
        float: left;
        padding-right: 8px;
    }

    #adauga-activit-admin p {
        text-align: justify;
        text-indent: 30px;
        line-height: 26px;
        font-size: 19px;
        margin-top: 10px;
    }

    #adauga-activit-admin h4 {
        text-align: left;
    }
        
    @media only screen and (max-width: 991px) {
        div#adauga-activit-admin {
        margin-left: 7px;
        }
    }
</style>