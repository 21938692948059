import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { saveAs } from 'file-saver';
// import { VueReCaptcha } from 'vue-recaptcha-v3'


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'


library.add(fas,fab)


const urlApiDev = 'http://pocu-sihlea.localhost/src/php/router.php'
const urlApiProd = 'https://pocusihlea140679.ro/src/php/router.php'


export default {
  php_url: process.env.NODE_ENV === 'development' ? urlApiDev : urlApiProd,    
}


createApp(App)
.component('fa',FontAwesomeIcon)
.use(router).mount('#app')

// .use(VueReCaptcha, { siteKey: '6LcvvBMjAAAAAA3airCHauoCe3TGQhvrdmD-n_oG',loaderOptions:{autoHideBadge: true } })

