<template>
    <ConfirmDialog :show="showDialog"
    :okButton="da"
    :noButton="nu"
    :cancel="cancel"
    :confirm="confirm"
    title="Atenție!"
    :description="descPopUp" />

    <div id="doc-admin" class="transition">
        <h4><fa class="me-2" icon="user" size="1x"/><b>ADMINISTRARE DOCUMENTE</b></h4>
        <hr>
        
        <div id="adauga-fisiere-uti" class="transition">
        
            <div class="d-flex flex-column mx-auto w-75">
                <div v-if="Message" id="alert-message" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
                    <span v-html='Message'></span>
                </div>
                <label class="my-3 text-start">
                    <input @change="handleMultiFileUpload()" ref="file" class="my-1 form-control" type="file" id="upload-files" multiple>
                </label>
        
                <button @click="uploadUserFiles()" class="btn btn-success mx-auto my-3 ">Salvează</button>
            </div>
            
        </div>
         <hr>
       
        <div>
            <div style="text-align: -webkit-center;" class="mb-3">
                <h3>Fișiere utilizator</h3>
                <hr class="mx-0 my-0" style="width:200px;">
                <span class="text-danger">(Click dreapta pe fișier pentru ștergere)</span>
            </div>
           

            <div id="user-files" class="d-flex row">
                <a @click.prevent="downloadUserFile(file)" @contextmenu.prevent = "callDialog(file)" class="col-sm-12 mt-2" v-for="file in fileList" :key="file" >
                    <label class="d-flex flex-column">
                        <fa class="me-2" icon="file" color="brown" size="3x"/>
                       {{file}}
                    </label>
                </a>

                
            </div>
        </div>
               
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import axios from "axios"
    import php_url from "../../main.js"
    import ConfirmDialog from '../dialogs/ConfirmDialog'

    export default defineComponent({
        components: { ConfirmDialog },
        inheritAttrs: false,
        name: 'IncarcaUserDoc',
        data() {
            return {
                da: "DA",
                nu: "NU",
                descPopUp: "",
                files: [],
                fileList: "",
                fileName: "",
                Message: "",
                reqSuccess: false,
                showDialog: false
            }
            
        },
        methods: {
            handleMultiFileUpload() {
                this.files = this.$refs.file.files
            },
            uploadUserFiles() {
                if(this.files.length == 0) {
                    this.Message = "Selectați cel puțin un fișier!"
                } else {
                    let totalfiles = this.files.length;

                    let formData = new FormData();

                    for (let i = 0; i < totalfiles; i++) {
                        formData.append("files[]", this.files[i]);
                    }

                    formData.append('action',"uploadUserFiles")

                    axios({
                            url: php_url.php_url,
                            method: 'post',
                            data: formData,
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            }
                        }).then((res) => {
                            // console.log(res.data);

                            if(res.data == 1) {
                                this.reqSuccess = true
                                this.files = []
                                this.Message = "Fișier(e) salvat(e) cu succes!"
                                this.getUserFiles()
                            } else if(res.data == 0) {
                                this.reqSuccess = false
                                this.Message = "Fișierul atașat nu este un document valid!"
                            } else if(res.data == 2) {
                                this.reqSuccess = false
                                this.Message = "Fișierul atașat există în baza de date! <br> Ștergeți-l mai întâi!"
                            } else if(res.data == 3) {
                                this.reqSuccess = false
                                this.Message = "Directorul nu a putut fi creat!"
                            } else {
                                this.reqSuccess = false
                                this.Message = "Eroare necunoscută!"
                            }
                        })
                }
                
            },
            getUserFiles() {
                axios.post(php_url.php_url,JSON.stringify({action:"getUserFiles",user_id:""})).then((res) => {
                    this.fileList = res.data.files
                })
            },
            downloadUserFile(filename) {
              
                let requestObject = JSON.stringify({action: "downloadUserFile",user_id:"",nume_fisier: filename});

                let request = async () => {
                    await fetch(php_url.php_url, {
                        method: "POST",
                        mode: 'cors',
                        credentials: 'include',
                        headers: {
                            // 'Accept': 'application/json',
                            // 'Content-Type': 'application/json'
                            // "Content-type": "application/x-www-form-urlencoded"
                        },
                        body: requestObject
                    })
                        .then(response => response.blob())
                        .then(blob => saveAs(blob, filename))
                };

                request();
            },
            callDialog(filename) {
                this.fileName = filename
                this.descPopUp = "Ștergeți fișierul <br><br>'"+filename+"' ?"
                this.showDialog = true
            },
            cancel() {
                // console.log('cancel');
                this.showDialog = false;
            },
            confirm() {
                this.showDialog = false;


                // STERGE stergeFisier

                if(this.fileName) {
                    axios.post(php_url.php_url,JSON.stringify({action:"stergeFisier",nume_fisier:this.fileName})).then((res) => {
                      
                        if(res.data.code == 1) {
                            this.fileName = ""
                            this.reqSuccess = true
                            this.Message = "Fișierul a fost șters cu succes!"
                            this.getUserFiles()
                        } else if(res.data.code == 2) {
                            this.reqSuccess = false
                            this.Message = "Fișierul e inexistent!"
                        } else {
                            this.reqSuccess = false
                            this.Message = "Eroare necunoscută!"
                        }
                    }) 
                }   
            },
 
        },
      
        mounted() {
            this.getUserFiles()
        }
        
    })
</script>

<style>

    #alert-message pre {
        margin-bottom: 0;
    }

    #doc-admin {
        /* border: 2px solid #395e8d; */
        border-radius: 5px;
        margin-bottom: 3px;
        height: calc(100vh - 197px);
        overflow-y: auto;
        padding: 5px 20px;

        font-family: sans-serif;
    }

    #adauga-fisiere-uti #upload-files {
        width: 30%;
        min-width: 300px;
        margin: 0 auto;
    }

    #adauga-fisiere-uti #alert-message {
        width: 65%;
        min-width: 300px;
    }

    #user-files  {
        justify-content: center;
        column-gap: 15px;
    }

    #user-files a {
        text-decoration: none;
        width: 158px;
        overflow-wrap: break-word;
    }

    #user-files a label {
        cursor: pointer;
    }

    #user-files a label svg:hover {
        color: rgba(211, 9, 9, 0.699);
    }


    #user-admin hr {
        margin-top: 3px;
    }



    #doc-admin h4 {
        text-align: left;
    }
        
</style>