<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
      <div class="col-sm-5 col-md-3 col-lg-2">
          <AnunturiSideBar />
      </div>
       <div class="col-sm-7 col-md-9 col-lg-10">
        <div id="adauga-loc-munca" class="transition">
        
    
        <h4><fa class="me-2 " icon="plus" color="green" size="1x"/><b>ADAUGĂ LOC MUNCĂ</b></h4>
        
            <hr>
            <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
                <span v-html="Message"></span>
            </div>

            <div class="d-flex flex-column mx-auto w-50">
            <label for="titlu-loc-munca-edit" class="my-3 text-start"><h6 class="mb-1 text-start"><b>Imagine reprezentativă</b></h6>
                <input @change="handleFileUpload()" ref="file" class="my-1 form-control" type="file" id="upload-image">
            </label>
            <label for="titlu-loc-munca-edit" class="my-1 text-center"><h6 class="mb-1 text-start"><b>Titlu</b></h6>
                <input style="height: 31px;" class="rounded-1 border border-2 border-primary w-100 p-2" type="text" id="titlu-anunt-edit"  v-model="titluLocMunca"/>
            </label>
            
            <label for="titlu-loc-munca-edit" class="my-2 text-center"><h6 class="mb-1 text-start"><b>Conținut</b></h6>
                <textarea class="rounded-1 border border-2 border-primary w-100 p-2" id="continut-anunt" rows="20" v-model="continutLocMunca"></textarea>
            </label>
            <button @click="uploadLocMunca()" class="btn btn-success mx-auto my-3 w-25">Salvează</button>
            </div>
        
            
        </div>
    
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios"
import php_url from "../main.js"

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'
import AnunturiSideBar from '../components/AnunturiSideBar'

export default defineComponent({
    components: {HeaderWebsite,FooterWebsite,AnunturiSideBar},
    name: 'AdaugareLocuriMunca',
    data() {
      return {
           
        titluLocMunca: "",
        continutLocMunca: "",
        file: "",
        Message: "",
        reqSuccess: false,
      }
    },
    methods: {
        uploadLocMunca() {
            if(!this.titluLocMunca) {
                this.Message = "Completați titlul anunțului!"
            } else if(!this.continutLocMunca) {
                this.Message = "Completați conținutul anunțului!"
            } else {
                let formData = new FormData();

                formData.append('file',this.file)
                formData.append('action',"uploadLocMunca")
                formData.append('titlu_loc_munca',this.titluLocMunca)
                formData.append('continut_loc_munca',this.continutLocMunca)

                axios({
                    url: php_url.php_url,
                    method: 'post',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    }
                }).then((res) => {
                    // console.log(res.data);

                    if(res.data == 1) {
                        this.reqSuccess = true
                        this.Message = "Anunț salvat cu succes! <br>Urmează să fie validat de către un administrator!"
                    } else if(res.data == 0) {
                        this.Message = "Fișierul atașat nu este imagine!"
                    } else if(res.data == 2) {
                        this.Message = "Fișierul atașat există în baza de date!"
                    } else {
                        this.Message = "Eroare necunoscută!"
                    }
                })
            }
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0]
        },
    },
  
})
</script>

<style>

#adauga-loc-munca {
        /* border: 2px solid #395e8d; */
        border-radius: 5px;
        margin-top: 3px;
        margin-bottom: 3px;
        height: calc(100vh - 178px);
        overflow-y: auto;
        padding: 20px;
        font-family: sans-serif;
    }

    #adauga-loc-munca hr {
        width:300px;
        margin-top: 8px;
    }

    #adauga-loc-munca img{
        width: 105px;
        display: flex;
        float: left;
        padding-right: 8px;
    }

    #adauga-loc-munca p {
        text-align: justify;
        text-indent: 30px;
        line-height: 26px;
        font-size: 19px;
        margin-top: 10px;
    }

    #adauga-loc-munca h4 {
        text-align: left;
    }
        
    @media only screen and (max-width: 991px) {
        div#adauga-loc-munca {
        margin-left: 7px;
        }
    }
</style>