<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->

  
  <router-view>
    
  </router-view>
</template>

<script>
// import axios from "axios"
// import php_url from "./main.js"


export default {
  name: 'App',
  components: {},


  data ()  {
    //
    return {
     currentRoute: ""
    } 
     
  }, 
  methods: {
  
  },

  mounted() {
 
  }
}



</script>

<style lang="scss">



body {
  /* line-height:unset!important; */
  overflow-y: hidden;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.transition {
  animation: fadeInAnimation ease 1.5s
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.transitionFast {
  animation: fadeInAnimation ease 1s
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    

    &.router-link-exact-active {
      color: #1ed7be;
    }
  }
}

.btn {
  box-shadow: 2px 3px 3px #ffffff9c;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #556cad;
     /* border-radius: 3px; */
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 3px;
}
</style>
