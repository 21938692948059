<template>

    <div id="adauga-anunt-admin" class="transition">
        
    
    <h4><fa class="me-2 " icon="plus" color="green" size="1x"/><b>ADAUGĂ ANUNȚ</b></h4>
    
        <hr>
    
        <div class="d-flex flex-column mx-auto w-75">
            <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
                {{Message}}
            </div>
            <label class="my-3 text-start"><h6 class="mb-1 text-start"><b>Imagine anunț</b></h6>
                <input @change="handleFileUpload()" ref="file" class="my-1 form-control" type="file" id="upload-image" >
            </label>
            <label class="my-1 text-center"><h6 class="mb-1 text-start"><b>Titlu anunț</b></h6>
                <input style="height: 31px;" class="rounded-1 border border-2 border-primary w-100 p-2" type="text" id="titlu-anunt-edit" v-model="titluAnunt" />
            </label>
            
            <label class="my-2 text-center"><h6 class="mb-1 text-start"><b>Conținut</b></h6>
                <textarea class="rounded-1 border border-2 border-primary w-100 p-2" id="continut-anunt" rows="15"  v-model="continutAnunt"></textarea>
            </label>
            <button @click="uploadAnunt()" class="btn btn-success mx-auto my-3 ">Salvează</button>
        </div>
        
    </div>

</template>

<script>
    import { defineComponent } from 'vue';
    import axios from "axios"
    import php_url from "../../main.js"

    export default defineComponent({
        name: 'AdaugaAnunt',
        props: ['idAnunt'],
        data() {
            return {
                titluAnunt: "",
                continutAnunt: "",
                file: "",
                Message: "",
                reqSuccess: false,
            }
        },
        methods: {
            uploadAnunt() {
                if(!this.titluAnunt) {
                    this.Message = "Completați titlul anunțului!"
                } else if(!this.continutAnunt) {
                    this.Message = "Completați conținutul anunțului!"
                } else {
                    let formData = new FormData();

                    formData.append('file',this.file)
                    formData.append('action',"uploadAnunt")
                    formData.append('titlu_anunt',this.titluAnunt)
                    formData.append('continut_anunt',this.continutAnunt)
                    formData.append('id_anunt',this.idAnunt)

                    // let data = {action:"uploadAnunt",titlu_anunt: this.titluAnunt,continut_anunt: this.continutAnunt}

                    axios({
                        url: php_url.php_url,
                        method: 'post',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Accept': 'application/json'
                        }
                    }).then((res) => {
                        // console.log(res.data);

                        if(res.data == 1) {
                            this.reqSuccess = true
                            this.Message = "Anunț salvat cu succes!"
                        } else if(res.data == 0) {
                            this.Message = "Fișierul atașat nu este imagine!"
                        } else if(res.data == 2) {
                            this.Message = "Fișierul atașat există în baza de date!"
                        } else {
                            this.Message = "Eroare necunoscută!"
                        }
                    })
                }
            },
            handleFileUpload() {
                this.file = this.$refs.file.files[0]
            },
            getAnunt() {
                axios.post(php_url.php_url,JSON.stringify({action:"getAnunt",id_anunt:this.idAnunt})).then((res) => {
                  
                    this.titluAnunt = res.data.titlu
                    this.continutAnunt = res.data.continut
                // Object.values(getData).forEach(val => {
                //   console.log(val.titlu);
                // });

                    })
            },
        },
        mounted() {
           this.getAnunt()
        }
        
    })
</script>

<style>
    #adauga-anunt-admin {
        /* border: 2px solid #395e8d; */
        border-radius: 5px;
        margin-bottom: 3px;
        height: calc(100vh - 204px);
        overflow-y: auto;
        padding-top: 10px;
        font-family: sans-serif;
    }


    #adauga-anunt-admin hr {
        width:100%;
        margin-top: 8px;
    }

    #adauga-anunt-admin img{
        width: 105px;
        display: flex;
        float: left;
        padding-right: 8px;
    }

    #adauga-anunt-admin p {
        text-align: justify;
        text-indent: 30px;
        line-height: 26px;
        font-size: 19px;
        margin-top: 10px;
    }

    #adauga-anunt-admin h4 {
        text-align: left;
    }
        
    @media only screen and (max-width: 991px) {
        div#adauga-anunt-admin {
        margin-left: 7px;
        }
    }
</style>