<template>
    <div id="panel-header" class="d-flex">
        <div class="row justify-content-center w-100">
            <div style="column-gap: 30px;" class="col-lg-4 flex-center">
                <div id="header-imgs">
                    <img  src="../assets/sigla_UE.png" title="P.O.C.U. SIHLEA" class="py-2">
                    <img  src="../assets/sigla_guvernul_romaniei.png" title="P.O.C.U. SIHLEA" class="py-2">
                    <img  src="../assets/sigla_instr_structurale.png" title="P.O.C.U. SIHLEA" class="py-2">
                </div>
                
                <router-link to="/" style="color: white;text-shadow: 4px 4px 4px #999393;font-size: 25px;text-shadow: 8px 9px 4px black;text-shadow: 8px 9px 4px black;font-size: 25px;" class="navbar-brand" ><b>P.O.C.U. SIHLEA<br><span style="font-size:18px">140679</span></b></router-link>
            </div>
            <div class="col-lg-5 flex-center">
                <h2 style="color: white;text-shadow: 4px 4px 4px grey;" >PANOU ADMINISTRARE</h2>
            </div>
            <div class="col-lg-3 flex-center">
                <router-link id="website" to="/" title="WEBSITE" class="float-end text-decoration-none me-3 h-panel-buttons">
                    <fa icon="house-user" color="white" size="2x"/>      
                    <span class="fw-bold">WEBSITE</span>
                </router-link>

                <router-link @click="logout()" id="panel-logout" to="/" title="LOGOUT" class="float-end text-decoration-none h-panel-buttons">
                    <fa icon="right-from-bracket" color="white" size="2x"/>      
                    <span class="fw-bold">LOGOUT</span>
                </router-link>
            </div>
        </div>
    </div>
    
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios"
import php_url from "../main.js"

export default defineComponent({
  name: 'PanelHeader',
  props: [''],
  data () {
    return {
       
    }
  },
    
  methods: {
    logout() {
            axios.post(php_url.php_url,JSON.stringify({action:"logout"})).then((res) => {
                // this.$router.push('/');
            })
        }
    }
    
})
</script>

<style>
    #panel-header{
        background-image: url('../assets/banner.jpg')!important;
        height: 115px;
    }

    #panel-header #header-imgs {
        display: flex;
        border-radius: 5px;
        background: linear-gradient(0deg, #e7e7e7, transparent);
    }

    #panel-header #header-imgs img {
        margin-right:5px;
        margin-left:10px;
        width: 69px;
    }

    #panel-header .h-panel-buttons {
        display: grid;
        place-items: center;
    }

    #panel-header a.h-panel-buttons span {
        color: white;
        font-size:14px;
        padding-top:5px;
    }
     
    #panel-header a.h-panel-buttons span:hover{
        color: rgb(111, 175, 228);
    }
    

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

 
</style>