<template>
    <ConfirmDialog :show="showDialog"
    :okButton="da"
    :noButton="nu"
    :cancel="cancel"
    :confirm="confirm"
    title="Atenție!"
    description="Doriți ștergerea postării?" />

    <div id="locuri-munca-admin" class="transition">
         
        <h4><fa class="me-2 " icon="bullhorn" size="1x"/><b>LOCURI DE MUNCĂ</b></h4>
        <hr>

        <div v-for="locMunca in locuriMunca" :key="locMunca.id" class="text-start transitionFast">
            <!-- <button ref="edit" @click="editLocMunca(locMunca.id)" class="btn btn-success me-3">Editare</button> -->
            <button ref="sterge"  @click="callDialog(locMunca.id)" class="btn btn-danger me-2">Șterge</button>
            <button ref="valideaza"  @click="toggleValidare(locMunca.id,locMunca.validat)" class="btn" :class="locMunca.validat == 0 ? 'btn-primary' : 'btn-dark'">
                <span v-if="locMunca.validat == 0 ">Validează</span>
                <span v-else if="locMunca.validat == 1 ">Invalidează</span>

            </button>

            <div style="margin-top: -28px;">
                <h5 class="fw-bold text-center">{{locMunca.titlu}}</h5>
                <hr class="mx-auto" style="width:200px;">
            </div>
            
            <img v-if="locMunca.cale_img" :src="locMunca.cale_img" title="Loc Muncă" class="">
            <p><span v-html='locMunca.continut'></span></p>
            <hr>
        </div>
        
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import axios from "axios"
    import php_url from "../../main.js"

    import ConfirmDialog from '../dialogs/ConfirmDialog'


    export default defineComponent({
        components: { ConfirmDialog },
        emits: ["editLocMunca"],
        inheritAttrs: false,
        // props: ['idAnunt'],
        name: 'LocuriMuncaAdmin',
        data() {
            return {
                locuriMunca: "",
                showDialog: false,
                da: "DA",
                nu: "NU",
                idLocMunca: ""
            }
        },
        methods: {
            getLocuriMunca() {
                    axios.post(php_url.php_url,JSON.stringify({action:"getLocuriMunca"})).then((res) => {
                    
                    this.locuriMunca = res.data
                    // Object.values(getData).forEach(val => {
                    //   console.log(val.titlu);
                    // });
        
                        })
                    },
            callDialog(locMuncaId) {
                this.idLocMunca = locMuncaId
                this.showDialog = true
            },

            cancel() {
                // console.log('cancel');
                this.showDialog = false;
            },
            confirm() {
                this.showDialog = false;

                // STERGE LOC MUNCA

                if(this.idLocMunca) {
                    axios.post(php_url.php_url,JSON.stringify({action:"stergeLocMunca",id_loc_munca:this.idLocMunca})).then((res) => {
                      
                        if(res.data.code == 1) {
                            this.getLocuriMunca()
                        } 
                    }) 
                }   
            },
            toggleValidare($idLocMunca,$validat) {
                
                // VALIDEAZA/INVALIDEAZA LOC MUNCA


                if($idLocMunca) {
                    axios.post(php_url.php_url,JSON.stringify({action:"toggleValidareLocMunca",id_loc_munca:$idLocMunca,validat:$validat})).then((res) => {
                     
                        if(res.data.code == 1) {
                            this.getLocuriMunca()
                        } 
                    }) 
                }   

            },

            editLocMunca(locMuncaId) {
                this.idLocMunca = locMuncaId
                this.$emit('editLocMunca', this.idLocMunca)
            }
        },
        mounted() {
            this.getLocuriMunca()
        }
        
    })
</script>

<style>
    #locuri-munca-admin {
        /* border: 2px solid #395e8d; */
        border-radius: 5px;
        margin-bottom: 3px;
        height: calc(100vh - 197px);
        overflow-y: auto;
        padding: 5px 20px;
        font-family: sans-serif;
    }

    #locuri-munca-admin hr {
        margin-top: 8px;
    }

    #locuri-munca-admin img{
        width: 25%;
        border-radius: 22px;
        cursor: pointer;
        display: flex;
        float: left;
        padding-right: 8px;
        min-height: 211px;
    }

    #locuri-munca-admin p {
        text-align: justify;
        line-height: 26px;
        font-size: 19px;
        margin-top: 10px;
        min-height: 259px;
    }

    #locuri-munca-admin h4 {
        text-align: left;
    }
        
    @media only screen and (max-width: 991px) {
        div#locuri-munca-admin {
            margin-left: 7px;
        }
    }
</style>