<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
      <div class="col-sm-5 col-md-3 col-lg-2">
          <AnunturiSideBar />
      </div>
       <div class="col-sm-7 col-md-9 col-lg-10">
        <div id="proiecte-content">
         
          <h4><fa class="me-2 " icon="diagram-project" size="1x"/><b>PROIECTE</b></h4>
          <hr>
        <br>
         <div style="width: 70%;margin: 0 auto;">
          <p>•	Proiect co-finantat din Programul Operaţional Capital Uman 2014 – 2020 POCU/827/5/2/140679, „Masuri integrate de combatere a marginalizarii si excluziunii sociale in comuna Sihlea, judetul Vrancea”</p>
          <p>•	Înființare sistem de apă în satele Caiata, Voetin și Bogza, comuna Sihlea– Școală nouă pentru copiii din Sihlea</p>
          <p>•	Modernizarea infrastructurii rutiere de drum judetean, dintre localitatile limita județ Buzău-Voetin-Sihlea-Obrejița-Tâmboești-Bordești cu conectivitate directa la reteaua TEN-T</p>
          <p>•	Modernizare drumuri comunale comuna Sihlea</p>
          <p>•	Construire școală generală cu 6 săli de clasă comună Sihlea, județul Vrancea</p>
          <p>•	Reabilitare, modernizare, dotări și împrejmuire grădiniță Voetin</p>
          <p>•	Centrul multifuncțional de asistență comunitară "Alexandru Sihleanu", comuna Sihlea</p>
          <p>•	Asfaltarea a 6 km de drumuri comunale din satele Sihlea, Căiata și Bogza</p>

         </div>
        
        </div>
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'
import AnunturiSideBar from '../components/AnunturiSideBar'

export default defineComponent({
  name: 'Proiecte',
    data() {
      return {
        currentRouteName: useRoute().name
      }
    },
  components: {HeaderWebsite,FooterWebsite,AnunturiSideBar},
  mounted() {
  //  console.log(this.currentRouteName);
  }
})
</script>

<style>

  #proiecte-content {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    overflow-y: auto;
    padding: 20px;
    font-family: sans-serif;
  }

  #proiecte-content hr {
    width:200px;
    margin-top: 8px;
  }

  #proiecte-content img{
    width: 105px;
    display: flex;
    float: left;
    padding-right: 8px;
  }

  #proiecte-content p {
    text-align: justify;
    text-indent: 30px;
    line-height: 26px;
    font-size: 19px;
  }

  #proiecte-content h4 {
    text-align: left;
  }
    
  @media only screen and (max-width: 991px) {
    div#proiecte-content {
      margin-left: 7px;
    }
  }

</style>