<template>
  <ConfirmDialog :show="showDialog"
  okButton="DA"
  noButton="NU"
  :cancel="cancel"
  :confirm="confirm"
  title="Atenție!"
  description="Sigur doriți să ștergeți?" />

  <h4 style="text-align:left;"><fa class="me-2 " icon="address-card" size="1x"/><b>CHESTIONAR ASISTENȚĂ SOCIALĂ ȘI CONSILIERE</b></h4>
  <hr class="my-0">
    <div class="row w-100 transition" id="asis-cons-admin">
      
      <div class="col-sm-12 col-md-4 col-lg-3">
        <span class="text-danger">(Click dreapta pe Solicitant pentru ștergere)</span>
        <select class="form-select" id="asis-select" size="5">
            <option v-for="asis in asisList" :key="asis.id" @click = "loadIdPers(asis.id)" @contextmenu.prevent = "callDialog(asis.id)">{{asis.nume_prenume}} </option>
        </select>
      </div>
      

      <div class="p-3 col-sm-12 col-md-8 col-lg-9" >
        <div v-if="Message" id="alert-message" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
          <span v-html='Message'></span>
        </div>
        <a @click.prevent="downloadChest()" class="d-flex flex-column text-decoration-none" href="" title="Descarcă" download><fa class="me-2" icon="file-word" size="3x"/> <span class="pt-1 pb-3"><b>Chestionar</b></span></a>
      </div>
      
    </div>
   
  
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios"
import php_url from "../../main.js"

import ConfirmDialog from '../dialogs/ConfirmDialog'

export default defineComponent({
  name: 'AsisConsAdmin',
    data() {
      return {
        loggedUserId: "",
        loggedUserAccess: "",
        asisList: "",
        asisSelect: "",
        showDialog: false,
        Message: "",
        reqSuccess: false,
      }
    },
  components: {ConfirmDialog},
  inheritAttrs: false,
  methods: {
    getAsisConsList() {
      axios.post(php_url.php_url,JSON.stringify({action:"getAsisConsList"})).then((res) => {
          this.asisList = res.data
      })
    },
    ckAccess() {
        axios.post(php_url.php_url,JSON.stringify({action:"ckAccess"})).then((res) => {
                this.loggedUserAccess = res.data.access
                this.loggedUserId = res.data.idCurrUti
                
            })
    },
    callDialog(asisId) {
        this.showDialog = true
        this.asisSelect = asisId
    },
    loadIdPers(asisId) {
      this.asisSelect = asisId
    },
    cancel() {
        // console.log('cancel');
        this.showDialog = false;
    },
    confirm() {
        this.showDialog = false

        // STERGE Solicitant si Chestionar

        if(this.asisSelect) {
        
          axios.post(php_url.php_url,JSON.stringify({action:"stergeSolChest",sol_chest:this.asisSelect})).then((res) => {
            
            if(res.data.code == 1) {
                this.getAsisConsList()
            } 
          }) 
        }   
    },
    downloadChest() {

      if(!this.asisSelect) {
        this.reqSuccess= false
        this.Message = "Selectați un solicitant din listă!"
      } else {
        let requestObject = JSON.stringify({action: "downloadChest",user_id: this.asisSelect});
        let filename = "Chestionar Asistență Consiliere.docx" 

        let request = async () => {
            await fetch(php_url.php_url, {
                method: "POST",
                mode: 'cors',
                credentials: 'include',
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json'
                    // "Content-type": "application/x-www-form-urlencoded"
                },
                body: requestObject
            })
                .then(response => response.blob())
                .then(blob => saveAs(blob, filename))
                .then(this.Message = "")
        };

        request();
      }
              
      
    },
    
  },
  computed: {
    
  },
  mounted() {
      this.getAsisConsList()
      // this.ckAccess()
  },
})
</script>

<style>

  #asis-cons-admin #asis-select {
      height: calc(100vh - 262px);
      cursor: pointer;
      font-size: 18px;
  }

  #asis-cons-admin div#select-list {
      /* border-right: 2px solid gray; */
      padding-right: 21px;
  }

  #asis-cons-admin #select-list select {
      cursor: pointer;
      font-size: 18px;
  }

  #asis-cons-admin {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    overflow-y: auto;
    padding: 20px;
    font-family: sans-serif;
  }

  #asis-cons-admin hr {
    width:200px;
    margin-top: 8px;
  }

  #asis-cons-admin p {
    text-align: justify;
    line-height: 26px;
    font-size: 19px;
  }

  
  
  @media only screen and (max-width: 991px) {
    div#asis-cons-admin {
      margin-left: 7px;
    }
  }

</style>