import { createRouter, createWebHistory } from 'vue-router'
import Prezentare from '../views/Prezentare'
import Anunturi from '../views/Anunturi'
import Activitati from '../views/Activitati'
import Proiecte from '../views/Proiecte'
import Parteneri from '../views/Parteneri'
import AdaugareLocuriMunca from '../views/LocuriMunca'
import Consiliere from '../views/Consiliere'
import Contact from '../views/Contact'
import Panel from '../views/Panel'
import Login from '../views/Login'
import InregistrareUti from '../views/InregistrareUti'

import axios from "axios"
axios.defaults.withCredentials = true;
import php_url from "../main.js"

const routes = [
  {
    path: '/',
    name: '',
    component: Prezentare,
    meta: {
      authRequired: 'false',
    },
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/prezentare',
    name: 'prezentare',
    component: Prezentare,
    meta: {
      authRequired: 'false',
    },
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/anunturi',
    name: 'anunturi',
    component: Anunturi,
    meta: {
      authRequired: 'false',
    },
    // meta: {
    //   title: "News",
    // }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/activitati',
    name: 'activitati',
    component: Activitati,
    meta: {
      authRequired: 'false',
    },
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/proiecte',
    name: 'proiecte',
    component: Proiecte,
    meta: {
      authRequired: 'false',
    },
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/parteneri',
    name: 'parteneri',
    meta: {
      authRequired: 'false',
    },
    component: Parteneri,
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/locuri-munca',
    name: 'locuri-munca',
    component: AdaugareLocuriMunca,
    meta: {
      authRequired: 'true',
    },
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/consiliere',
    name: 'consiliere',
    component: Consiliere,
    meta: {
      authRequired: 'false',
    },
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      authRequired: 'false',
    },
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/panel',
    name: 'panel',
    component: Panel,
    meta: {
      authRequired: 'true',
    },
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      authRequired: 'false',
    },
    
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  {
    path: '/inregistrare-utilizator',
    name: 'inregistrare-utilizator',
    component: InregistrareUti,
    meta: {
      authRequired: 'false',
    },
    
    // meta: {
    //   title: "Social Innovation",
    // }
  },
  // redirect

  // catch all
  {
    path: '/:catchAll(.*)',
    name: 'prezentare',
    component: Prezentare
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const default_title = "P.O.C.U. Sihlea";

router.beforeEach((to,from,next) => {
  document.title = `${default_title}`;

  if (to.meta.authRequired === 'true') {

    axios.post(php_url.php_url,JSON.stringify({action:"ckAccess"})).then((res) => {
      let access = Number(res.data.access)

      if ([1,2,3,4].includes(access)) {
        next();
      } else {
        router.push({
          name: 'login'
        })
      }
    })
  } else {
    next();
  }
  
});



export default router
