<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
      <div class="col-sm-5 col-md-3 col-lg-2">
          <AnunturiSideBar />
      </div>
       <div class="col-sm-7 col-md-9 col-lg-10">
        <div id="parteneri-content">
         
          <h4><fa class="me-2 " icon="handshake" size="1x"/><b>PARTENERI</b></h4>
          <hr>
        <br>
          <div style="width: 70%;margin: 0 auto;">
            <p> Asociatia Angels Garden este Partener in cadrul proiectului si detine experienta relevanta pentru a lua parte la proiect, asa cum este mentionat si in cererea de finantare. <br>
              De asemenea, un aspect foarte relevant in selectarea acestuia a fost ca detine acreditarea de servicii sociale conform prevederilor legislative in vigoare.<br><br>
              Pe langa activitatile sociale, Partenerul va fi implicat in activitatile de antreprenoriat.
              </p>
          </div>
          
          <br>
        </div>
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'
import AnunturiSideBar from '../components/AnunturiSideBar'

export default defineComponent({
  name: 'Parteneri',
    data() {
      return {
       
      }
    },
  components: {HeaderWebsite,FooterWebsite,AnunturiSideBar}
})
</script>

<style>

  #parteneri-content {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    overflow-y: auto;
    padding: 20px;
    font-family: sans-serif;
  }

  #parteneri-content hr {
    width:200px;
    margin-top: 8px;
  }

  #parteneri-content img{
    width: 105px;
    display: flex;
    float: left;
    padding-right: 8px;
  }

  #parteneri-content p {
    text-align: justify;
    text-indent: 30px;
    line-height: 26px;
    font-size: 19px;
  }

  #parteneri-content h4 {
    text-align: left;
  }
    
  @media only screen and (max-width: 991px) {
    div#parteneri-content {
      margin-left: 7px;
    }
  }

</style>