<template>
    <ConfirmDialog :show="showDialog"
    :okButton="da"
    :noButton="nu"
    :cancel="cancel"
    :confirm="confirm"
    title="Atenție!"
    description="Doriți ștergerea anunțului?" />

    <div id="news-list-admin" class="transition">
         
        <h4><fa class="me-2 " icon="bullhorn" size="1x"/><b>ANUNȚURI</b></h4>
        <hr>

        <div v-for="anunt in anunturi" :key="anunt.id" class="text-start transitionFast">
            <button ref="edit" @click="editAnunt(anunt.id)" class="btn btn-success me-3">Editare</button>
            <button ref="sterge"  @click="callDialog(anunt.id)" class="btn btn-danger">Șterge</button>

            <div style="margin-top: -28px;">
                <h5 class="fw-bold text-center">{{anunt.titlu}}</h5>
                <hr class="mx-auto" style="width:200px;">
            </div>
            
            <img :src="anunt.cale_img" title="Anunt" class="">
            <p><span v-html='anunt.continut'></span></p>
            <hr>
        </div>
        
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import axios from "axios"
    import php_url from "../../main.js"

    import ConfirmDialog from '../dialogs/ConfirmDialog'


    export default defineComponent({
        components: { ConfirmDialog },
        emits: ["editAnunt"],
        inheritAttrs: false,
        // props: ['idAnunt'],
        name: 'AnunturiAdmin',
        data() {
            return {
                anunturi: "",
                showDialog: false,
                da: "DA",
                nu: "NU",
                idAnunt: ""
            }
        },
        methods: {
            getAnunturi() {
                    axios.post(php_url.php_url,JSON.stringify({action:"getAnunturiAdmin"})).then((res) => {
                    
                    this.anunturi = res.data
                    // Object.values(getData).forEach(val => {
                    //   console.log(val.titlu);
                    // });
        
                        })
                    },
            callDialog(anuntId) {
                this.idAnunt = anuntId
                this.showDialog = true
            },

            cancel() {
                // console.log('cancel');
                this.showDialog = false;
            },
            confirm() {
                // this.idAnunt = this.$refs.sterge.this.value
                // console.log(this.idAnunt);
                this.showDialog = false;

                // STERGE ANUNT

                if(this.idAnunt) {
                    axios.post(php_url.php_url,JSON.stringify({action:"stergeAnunt",id_anunt:this.idAnunt})).then((res) => {
                      
                        if(res.data.code == 1) {
                            this.getAnunturi()
                        } 
                    }) 
                }   
            },

            editAnunt(anuntId) {
                this.idAnunt = anuntId
                this.$emit('editAnunt', this.idAnunt)
            }
        },
        mounted() {
            this.getAnunturi()
        }
        
    })
</script>

<style>
    #news-list-admin {
        /* border: 2px solid #395e8d; */
        border-radius: 5px;
        margin-bottom: 3px;
        height: calc(100vh - 197px);
        overflow-y: auto;
        padding: 5px 20px;

        font-family: sans-serif;
    }


    #news-list-admin hr {
        margin-top: 8px;
    }

    #news-list-admin img{
        width: 25%;
        border-radius: 22px;
        cursor: pointer;
        display: flex;
        float: left;
        padding-right: 8px;
        min-height: 210px;
    }

    #news-list-admin p {
        text-align: justify;
        min-height: 244px;
        line-height: 26px;
        font-size: 19px;
        margin-top: 10px;
    }

    #news-list-admin h4 {
        text-align: left;
    }
        
    @media only screen and (max-width: 991px) {
        div#news-list-admin {
        margin-left: 7px;
        }
    }
</style>