<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
      <div class="col-sm-5 col-md-3 col-lg-2">
          <AnunturiSideBar />
      </div>
       <div class="col-sm-7 col-md-9 col-lg-10">
        <div id="presentation-content">
         
          <h6><fa class="me-2 " icon="location-dot" size="2x"/><b>AȘEZARE</b></h6>
          <hr>
          <img src="../assets/harta_sihlea.jpg" title="Sihlea">
          <p> Comuna se află în extremitatea sudică a județului, la limita cu județul Buzău, pe cele două maluri ale râului Slimnic și pe malul stâng al emisarului acestuia, Coțatcu.<br>
          Este traversată de șoseaua județeană DJ202E, care o leagă spre vest de Obrejița (unde se intersectează cu DN2) și mai departe de Tâmboești;<br>
          Spre sud în județul Buzău de Râmnicelu. Prin nord este traversată și de șoseaua națională DN2N, care o leagă spre est de Tătăranu (unde se termină în DN23A) și spre vest de Dumbrăveni (unde se intersectează cu același DN2), Bordești, Dumitrești, Chiojdeni și Jitia.<br>

          Prin comună trece și calea ferată Buzău-Mărășești, pe care este deservită de stația Sihlea</p>
          <br>

          <h6><fa class="me-2 " icon="monument" size="2x"/><b>MONUMENTE ISTORICE</b></h6>
          <hr>
         
          <p>Cinci obiective din comuna Sihlea sunt incluse în lista monumentelor istorice din județul Vrancea ca monumente de interes local. Două sunt situri arheologice — așezarea eneolitică de la „Movila Mică” de la Căiata, aparținând culturii Cucuteni; precum și situl de la „Movila Mare” ce cuprinde o așezare fortificată din perioada Halstatt și o așezare medievală datând din secolul al XVI-lea. Biserica „Adormirea Maicii Domnului” (zidită în 1808) de la Voetin este clasificată ca monument de arhitectură. <br>
          Ansamblul memorial și de arhitectură Sihleanu-Grădișteanu-Ghica din satul Sihlea, datând din secolul al XIX-lea, cuprinde castelul Sihleanu-Grădișteanu-Ghica (1830), biserica „Sfinții Împărați Constantin și Elena” (1860–1866) și bustul poetului Alexandru Sihleanu (1914); ansamblul este clasificat ca monument memorial sau funerar. <br>
          Un al cincilea monument, tot din categoria monumentelor memoriale sau funerare, este monumentul eroilor din Primul Război Mondial, monument ridicat în 1937 și aflat în curtea școlii generale din Voetin.</p>

          <h6><fa class="me-2 " icon="bullseye" size="2x"/><b>OBIECTIVUL GENERAL AL PROIECTULUI</b></h6>
          <hr>

          <p>Reducerea numărului de persoane aparținând comunității marginalizate din comuna Sihlea, aflate in risc de sărăcie și excluziune socială, prin implementarea de intervenții integrate de ocupare, educație, formare profesională, asistență socială, în contextul mecanismului DLRC.<br>
          Prin obiectivul sau general si cele specifice, proiectul contribuie la realizarea obiectivului major al POCU 2014-2020 care urmareste dezvoltarea resurselor umane prin formare profesională de calitate (200 adulți), antreprenoriat, reducerea sărăciei și a excluziunii sociale prin facilitarea accesului de servicii sociale 251 persoane, proiectul urmărind reducerea sărăciei excesive în comunitățile marginalizate prin
          măsuri de ocupare, educație și formare profesională, asistență socială, precum și orice alte măsuri menite să ajute aceste categorii sociale.<br>
          Prin intervențiile integrate pe care le promovează, proiectul contribuie la realizarea obiectivului specific 5.2. (AP5, OT9, PI9vi, OS 5.2) care implică scoaterea din starea de sărăcie și excluziune socială a comunităților marginalizate din zona rurală cu o populație sub 20.000 locuitori corelat cu cerințele mecanismului DLRC, comunități pentru care au fost elaborate și aprobate Strategii de Dezvoltare Locală prin
          PNDR Axa LEADER. Prin inițiativele propuse în cadrul Strategiilor Locale s-au depus și aprobat proiecte ce vizează rezolvarea unor probleme locale de natură socială continuate prin intermediul prezentului proiect.<br>
          Efectele pozitive pe termen lung generate de proiect pentru comunitatea în care vor avea loc intervențiile sunt legate de măsurile integrate de educație, formare, ocupare, antreprenoriat, servicii sociale de calitate și nu în ultimul rand îmbunătățirea condițiilor de trai și conștientizarea problemelor cu care se confruntă populația marginalizată (discriminare, sărăcie, excluziune socială) în scopul soluționării acestora. Toate demersurile vor duce la scoaterea din starea de sărăcie a unui grup considerabil de persoane care traiesc în condiții de marginalizare. <br>
          O parte a acestora își vor găsi locuri de muncă sau vor deveni ocupați pe cont propriu ieșind astfel din situația de asistat social.
          </p>

          <h6><fa class="me-2 " icon="hand-pointer" size="2x"/><b>OBIECTIVELE SPECIFICE ALE PROIECTULUI</b></h6>
          <hr>

          <p>
            1. Dezvoltare a unui serviciu social in cadrul comunitatii marginalizate din comuna Sihlea <br> 
            2. Informarea, consilierea, orientarea si medierea pe piata muncii a 251 membri ai grupului tinta<br> 
            3. 200 membri ai grupului tinta formati profesional prin participarea la cursuri de calificare<br> 
            4. Crearea a 49 de locuri de munca pentru membri comunitatii<br> 
            5. Sprijinirea a 14 membri ai grupului tinta in vederea infiintarii unei noi afaceri<br> 
            6. Două Workshop-uri si Campanii de informare si constientizare care au ca scop implicarea comunitatii in problemele sociale locale<br> 
            7. 24 de luni de management de proiect<br> 

          </p>
        </div>
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'
import AnunturiSideBar from '../components/AnunturiSideBar'

export default defineComponent({
  name: 'Prezentare',
    data() {
      return {
       
      }
    },
  components: {HeaderWebsite,FooterWebsite,AnunturiSideBar}
})
</script>

<style>

  #presentation-content {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    overflow-y: auto;
    padding: 20px;
    font-family: sans-serif;
  }

  #presentation-content hr {
    width:200px;
    margin-top: 8px;
  }

  #presentation-content img{
    width: 180px;
    border-radius: 10px;
    display: flex;
    float: left;
    padding-right: 8px;
  }

  #presentation-content p {
    text-align: justify;
    /* text-indent: 20px; */
    line-height: 26px;
    font-size: 19px;
  }

  #presentation-content h6 {
    text-align: left;
  }
    
  @media only screen and (max-width: 991px) {
    div#presentation-content {
      margin-left: 7px;
    }
  }

</style>