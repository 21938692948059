<template>
  <PanelHeader />
    <!-- <div style="--bs-gutter-x: 6px; height: calc(100vh - 172px);" class="transition">
      <PanelSideBar />

    </div> -->

    <div id="panel-sidebar" class="transition">
        <div class="container-fluid">
            <div class="row flex-nowrap">
                <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark" id="left-sidebar">
                    <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                        <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                            <span class="fs-5 d-none d-sm-inline"></span>
                        </a>
                        <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                           
                            <li v-if="access == 1">    
                                <a href="#submenu1" data-bs-toggle="collapse" class="nav-link px-0 align-middle" id="anunturi">
                                    <fa icon="newspaper" size="lg" color="white" style="margin-right: 4px;"></fa> <span class="ms-1 d-none d-sm-inline sharp-button">Anunțuri</span> </a>
                                <ul class="collapse show nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                                    <li class="w-100">
                                        <a @click="activeComponent = 'AdaugaAnunt';computeIdAnunt();" href="#" class="nav-link px-0 submenu"> <fa icon="plus" ></fa><span class="d-none d-sm-inline ">Adaugă anunț</span></a>
                                    </li>
                                    <li>
                                        <a @click="activeComponent = 'AnunturiAdmin'" href="#" class="nav-link px-0 submenu"> <fa icon="pen-to-square" ></fa><span class="d-none d-sm-inline ">Editare</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="access == 1">    
                                <a href="#submenu2" data-bs-toggle="collapse" class="nav-link px-0 align-middle" id="activitati">
                                    <fa icon="chart-line" size="lg" color="white" style="margin-right: 4px;"></fa> <span class="ms-1 d-none d-sm-inline sharp-button">Activități</span> </a>
                                <ul class="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                                    <li class="w-100">
                                        <a @click="activeComponent = 'AdaugaActivitate';computeIdActivit()" href="#" class="nav-link px-0 submenu"> <fa icon="plus" ></fa><span class="d-none d-sm-inline ">Adaugă activitate</span></a>
                                    </li>
                                    <li>
                                        <a @click="activeComponent = 'ActivitAdmin'" href="#" class="nav-link px-0 submenu"> <fa icon="pen-to-square" ></fa><span class="d-none d-sm-inline ">Editare</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="[1].includes(access)">    
                                <a href="#submenu3" data-bs-toggle="collapse" class="nav-link px-0 align-middle" id="locuri-munca">
                                    <fa icon="laptop-file" size="lg" color="white" style="margin-right: 4px;"></fa> <span class="d-none d-sm-inline sharp-button">Locuri muncă</span> 
                                </a>
                                <ul class="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                                    <li>
                                        <a @click="activeComponent = 'LocuriMuncaAdmin'" href="#" class="nav-link px-0 submenu"> <fa icon="pen-to-square" ></fa><span class="d-none d-sm-inline ">Aprobare</span></a>
                                    </li>
                                   
                                </ul>
                            </li>
                            <li v-if="access == 1">    
                                <a href="#submenu4" data-bs-toggle="collapse" class="nav-link px-0 align-middle" id="consiliere-asistenta">
                                    <fa icon="person-shelter" size="lg" color="white" style="margin-right: 4px;"></fa> <span class="ms-1 d-none d-sm-inline sharp-button">Consiliere și Asistență</span> 
                                </a>
                                <ul class="collapse nav flex-column ms-1" id="submenu4" data-bs-parent="#menu">
                                    <li>
                                        <a @click="activeComponent = 'AsisConsAdmin'" href="#" class="nav-link px-0 submenu"> <fa icon="pen-to-square" ></fa><span class="d-none d-sm-inline ">Chestionar Asistență</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#submenu5" data-bs-toggle="collapse" class="nav-link px-0 align-middle" id="management">
                                    <fa icon="people-roof" size="lg" color="white"></fa> <span class="ms-1 d-none d-sm-inline sharp-button">Management</span></a>
                                <ul class="collapse nav flex-column ms-1" id="submenu5" data-bs-parent="#menu">
                                    <li v-if="access == 1" class="w-100">
                                        <a @click="activeComponent = 'UtilizatoriAdmin'" href="#" class="nav-link px-0 submenu"> <fa icon="user" ></fa><span class="d-none d-sm-inline">Utilizatori</span> </a>
                                    </li>
                                    <li>
                                        <a @click="activeComponent = 'IncarcaUserDoc'" href="#" class="nav-link px-0 submenu"> <fa icon="file-arrow-up" ></fa><span class="d-none d-sm-inline">Încarcă documente</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="access == 1">
                                <a href="#submenu6" data-bs-toggle="collapse" class="nav-link px-0 align-middle" id="corespondenta">
                                    <fa icon="envelope" size="lg" color="white" style="margin-right: 5px;"></fa> <span class="ms-1 d-none d-sm-inline sharp-button">Corespondență</span> </a>
                                    <ul class="collapse nav flex-column ms-1" id="submenu6" data-bs-parent="#menu">
                                    <li class="w-100">
                                        <a @click="activeComponent = 'CorespondentaUseri'" href="#" class="nav-link px-0 submenu"> <fa icon="paper-plane" ></fa><span class="d-none d-sm-inline">Trimite email</span> </a>
                                    </li>
                                    <!-- <li>
                                        <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 2</a>
                                    </li>
                                    <li>
                                        <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 3</a>
                                    </li>
                                    <li>
                                        <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 4</a>
                                    </li> -->
                                </ul>
                            </li>
                            <!-- <li>
                                <a href="#" class="nav-link px-0 align-middle">
                                    <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">Customers</span> </a>
                            </li> -->
                        </ul>
                        <hr>
                        <div style="padding-bottom:300px;" class="dropdown">
                            <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
                                <span class="d-none d-sm-inline mx-1">{{numeUti}}</span>
                            </a>
                            <!-- <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                                 <li><a class="dropdown-item" href="#">New project...</a></li> 
                                <li><a class="dropdown-item" href="#">Profil</a></li>
                                <li><a class="dropdown-item" href="#">Setari</a></li>
                                
                                <li>
                                    <hr class="dropdown-divider">
                                </li>
                                <li><a class="dropdown-item" href="#">Log out</a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
                <div class="col py-2">
                    <component :idAnunt="idAnunt" :idActivit="idActivit" @editAnunt="showEditAnunt($event)" @editActivit="showEditActivit($event)" :is="activeComponent"/>
                  
                </div>
            </div>
        </div>
           
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';

import PanelHeader from '../components/PanelHeader'
import FooterWebsite from '../components/FooterWebsite'
import AnunturiAdmin from '../components/panel-content/AnunturiAdmin'
import AdaugaAnunt from '../components/panel-content/AdaugaAnunt'
import ActivitAdmin from '../components/panel-content/ActivitAdmin'
import AdaugaActivitate from '../components/panel-content/AdaugaActivitate'
import UtilizatoriAdmin from '../components/panel-content/UtilizatoriAdmin'
import IncarcaUserDoc from '../components/panel-content/IncarcaUserDoc'
import CorespondentaUseri from '../components/panel-content/CorespondentaUseri'
import LocuriMuncaAdmin from '../components/panel-content/LocuriMuncaAdmin'
import AsisConsAdmin from '../components/panel-content/AsisConsAdmin'

import axios from "axios"
import php_url from "../main.js"

export default defineComponent({
  name: 'Panel',
  components: { PanelHeader, FooterWebsite,AnunturiAdmin,AdaugaAnunt,AdaugaActivitate,ActivitAdmin,UtilizatoriAdmin,IncarcaUserDoc,CorespondentaUseri,LocuriMuncaAdmin,AsisConsAdmin},
    data() {
      return {
        access: "",
        activeComponent: "AnunturiAdmin",
        idAnunt: "",
        idActivit: "",
        numeUti: ""
      }
    },
    methods: {
        ckAccess() {
            axios.post(php_url.php_url,JSON.stringify({action:"ckAccess"})).then((res) => {
                    this.access = Number(res.data.access)
                    this.numeUti = res.data.numeUti
                })
            },
        computeIdAnunt() {
            this.idAnunt = ""
        },
        computeIdActivit() {
            this.idActivit = ""
        },
        showEditAnunt(idAnunt) {
            // console.log(idAnunt)
            this.idAnunt = idAnunt
            this.activeComponent = "AdaugaAnunt";
        },
        showEditActivit(idActivit) {
                
            this.idActivit = idActivit
            this.activeComponent = "AdaugaActivitate";
        },
        
    },
    mounted() {
        this.ckAccess()
    }
  
})
</script>

<style>

.sharp-button {
    border:none;
    padding:0.25em 3em 0.25em 1em;
    text-align:left;
    background:
        linear-gradient(-120deg, transparent 1em, #3d5ac1 1.05em , #3d5ac1 1.5em, transparent 1.45em, transparent 2em, #3d5ac1 2.05em) top no-repeat,
        linear-gradient(300deg, transparent 1em, #3d5ac1 1.05em , #3d5ac1 1.5em, transparent 1.45em, transparent 2em, #3d5ac1 2.05em) bottom no-repeat ;
    background-size: 100% 51%;
    color:white;
    border-radius: 3px;
    }

    #panel-sidebar .container-fluid {
        height: calc(100vh - 172px);
    }

    #left-sidebar {
        height: calc(100vh - 172px);
    }

    .submenu {
        color: rgb(255, 255, 255)!important;
    }
    

        @media only screen and (min-width: 515px) {
            a.submenu {
                float:left;
                margin-left:17px;
            }

            a.submenu svg{
                margin-right: 5px;
            }

            ul#menu li a:hover {
                animation: moveToRight 1s ;
            }
            
        /* #menu #anunturi:focus, #menu #anunturi:hover,#menu #management:focus, #menu #management:hover,#menu #corespondenta:focus, #menu #corespondenta:hover {
            margin-left:10px;
           
        } */
    }

    @keyframes moveToRight {
            0% {
                transform: translateX(0px);
            }
            100% {
                transform: translateX(20px);
            }
        }

</style>