<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
      <div class="col-sm-5 col-md-3 col-lg-2">
          <AnunturiSideBar />
      </div>
       <div class="col-sm-7 col-md-9 col-lg-10">
        <div id="contact-content">        
         <div style="width: 100%"><iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Sihlea,%20Vrancea+(P.O.C.U.%20Sihlea)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe></div>
         <hr style="width:100%">

          <div  class="row">

            <div class="col-lg-3">
              <h4><fa class="me-2 " icon="address-card" size="1x"/><b>CONTACT</b></h4>
            <hr>
            
              <p> Județ: Vrancea<br>
              Localitate: Sihlea<br>
              Adresa: Strada ...</p>
            </div>
            
            <div style="" class="col-lg-9" id="formular-content">
              <br>
                <h3><b>Formular Contact</b></h3>
               
                <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
                  {{Message}}
                </div>
              <input type="text" placeholder="Nume și prenume" v-model="numePrenume">
              <input type="text" placeholder="E-mail" v-model="email">
              <input type="number" placeholder="Telefon" v-model="telefon">
              <textarea class="my-2" rows="10" placeholder="Mesaj" v-model="mesaj"></textarea>
              <div class="my-3">
                <button @click="contactForm()" class="btn btn-success">Trimite</button>
                <button @click="resetFields()" class="btn btn-danger ms-5">Resetează</button>
              </div>
            </div>
          
          </div>

         
        </div>
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios"
import php_url from "../main.js"

import { load } from 'recaptcha-v3'

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'
import AnunturiSideBar from '../components/AnunturiSideBar'

export default defineComponent({
  name: 'Contact',
    data() {
      return {
        numePrenume: "",
        email: "",
        telefon: "",
        mesaj: "",
        Message: "",
        reqSuccess: false,
        token: ""
      }
    },
  components: {HeaderWebsite,FooterWebsite,AnunturiSideBar},
  methods: {
    contactForm() {

      if(!this.numePrenume) {
        this.reqSuccess= false
        this.Message = "Completați numele și prenumele!"
      } else if(!this.email) {
        this.reqSuccess= false
        this.Message = "Completați adresa de email!"
      } else if(!this.telefon) {
        this.reqSuccess= false
        this.Message = "Completați numărul de telefon!"
      } else if(!this.mesaj) {
        this.reqSuccess= false
        this.Message = "Completați conținutul mesajului!"
      } else {
        this.recaptchaToken().then(() => {
          axios.post(php_url.php_url,JSON.stringify({action:"sendContactForm",nume_prenume:this.numePrenume,email:this.email,telefon:this.telefon,mesaj:this.mesaj,token:this.token})).then((res) => {
          
            if(res.data == 1) {
              this.reqSuccess= true
              this.Message = "Mesajul a fost trimis cu succes!"
              this.resetFields()
            } else {
              this.reqSuccess = false
              this.Message = "Eroare necunoscută!"
            }
            
          })
        })
      }
    },
    async recaptchaToken() {
      const recaptcha =  await load('6LcvvBMjAAAAAA3airCHauoCe3TGQhvrdmD-n_oG',{autoHideBadge: false})
      recaptcha.showBadge()
      let token = await recaptcha.execute('login')

      this.token = token

      setTimeout(function() {
        recaptcha.hideBadge()
      }.bind(this),3000)

    },
    resetFields() {
      this.numePrenume = ""
      this.email = ""
      this.telefon = ""
      this.mesaj = ""
    }
  }
})
</script>

<style>

  #contact-content {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    overflow-y: auto;
    padding: 20px;
    font-family: sans-serif;
  }

  #contact-content hr {
    width:200px;
    margin-top: 8px;
  }

  #contact-content img{
    width: 105px;
    display: flex;
    float: left;
    padding-right: 8px;
  }

  #contact-content p {
    text-align: justify;
    line-height: 26px;
    font-size: 19px;
  }

  #contact-content h4 {
    text-align: left;
  }

  #contact-content #formular-content {
    border: solid 2px #2f3d7e;
    border-radius: 5px;
    width: 100%;
    display: grid;
    box-shadow: 5px 6px 7px;
    background-color: #1d2d5a;
    margin-left: 7%;
    max-width: 500px;
  }

  #contact-content #formular-content input {
      margin-bottom: 8px;
      margin-top: 8px;
      border-radius: 5px;
      height: 32px;
      font-size: 14px;
      padding: 7px;
  }

    #contact-content #formular-content textarea {
      border-radius: 5px;
      padding: 7px;
    }


  #contact-content #formular-content hr {
    margin: 0 auto;
  }

  #contact-content #formular-content h3 {
    color: #ffffff;
    text-shadow: 3px 4px 5px #2080d3;
  }
  
    
  @media only screen and (max-width: 991px) {
    div#contact-content {
      margin-left: 7px;
    }

    #contact-content #formular-content {
      margin-left: 0;
    }
  }

</style>