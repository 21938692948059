<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
      <div class="col-sm-5 col-md-3 col-lg-2">
          <AnunturiSideBar />
      </div>
       <div class="col-sm-7 col-md-9 col-lg-10">
        <div id="activitati-content">
         
          <h4><fa class="me-2 " icon="arrows-spin" size="1x"/><b>ACTIVITĂȚI</b></h4>
          <hr>
        
          <div v-for="activit in activitati" :key="activit.id">
            <div class="d-flex flex-column align-items-center">
              <h5 class="fw-bold">{{activit.titlu}}</h5>
              <hr class="" style="width:40%;">
            </div>
            
            <img :src="activit.cale_img" title="Anunt" class="">
            <p> <span v-html='activit.continut'></span></p>
            <hr>

          </div>
        
        </div>
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios"
import php_url from "../main.js"

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'
import AnunturiSideBar from '../components/AnunturiSideBar'


export default defineComponent({
  name: 'Activitati',
    data() {
      return {
        activitati: ""
      }
    },
  components: {HeaderWebsite,FooterWebsite,AnunturiSideBar},
  methods: {
    getActivitati() {
            axios.post(php_url.php_url,JSON.stringify({action:"getActivitati"})).then((res) => {
             
                this.activitati = res.data
 
              })
            },
    },
    mounted() {
      this.getActivitati()
    }
})
</script>

<style>

  #activitati-content {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    overflow-y: auto;
    padding: 20px;
    font-family: sans-serif;
  }

  #activitati-content hr {

    margin-top: 8px;
  }

  #activitati-content img{
    cursor: pointer;
    width: 15%;
    border-radius: 22px;
    display: flex;
    float: left;
    padding-right: 8px;
  }

  #activitati-content p {
    text-align: justify;
  
    line-height: 26px;
    font-size: 19px;
  }

  #activitati-content h4 {
    text-align: left;
  }
    
  @media only screen and (max-width: 991px) {
    div#activitati-content {
      margin-left: 7px;
    }
  }

</style>