<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
      <div class="col-sm-5 col-md-3 col-lg-2">
          <AnunturiSideBar />
      </div>
       <div class="col-sm-7 col-md-9 col-lg-10" id="news-content">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-7" id="anunturi">
            <h4><fa class="me-2 " icon="bullhorn" size="1x"/><b>ANUNȚURI</b></h4>
            <hr>
          
            <div v-for="anunt in anunturi" :key="anunt.id">
              <div>
                <h5 class="fw-bold">{{anunt.titlu}}</h5>
                <hr class="mx-auto" style="width:200px;">
              </div>
              
              <img :src="anunt.cale_img" title="Anunt" >
              <p> <span v-html='anunt.continut'></span></p>
              <hr>
            </div>
            
          </div>

          <div class="col-sm-12 col-md-6 col-lg-5" id="sectiune-cv">
            
            <h4 class="text-center">Descarcă model CV</h4>
            <hr>
            <a class="d-flex flex-column text-decoration-none" href="/files/Model-CV.docx" title="Descarcă" download><fa class="me-2" icon="file-word" size="3x"/> <span class="pt-1 pb-3"><b>Model CV</b></span></a>
            <div id="formular-cv">
           
                <br>
                  <h3><b>Depune CV</b></h3>
                 
                <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
                  {{Message}}
                </div>
                <input type="text" placeholder="Nume și prenume" v-model="numeCv">
                <input type="text" placeholder="E-mail" v-model="emailCv">
                <input type="number" placeholder="Telefon" v-model="telefonCv">
                <textarea class="my-2" rows="8" placeholder="Mesaj" v-model="mesajCv"></textarea>
               
                <label class="my-3 text-start"><h6 class="mb-1 text-start text-light"><b>Încarcă CV</b></h6>
                  <input type="file" @change="handleFileUpload()" ref="file" class="my-1 pl-0 form-control" id="upload-cv">
                </label>
                
                <div class="my-3">
                  <button @click="trimiteEmailCV()" class="btn btn-success">Trimite</button>
                  <button class="btn btn-danger ms-5">Resetează</button>
                </div>
              
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios"
import php_url from "../main.js"

import { load } from 'recaptcha-v3'

import HeaderWebsite from '../components/HeaderWebsite'
import FooterWebsite from '../components/FooterWebsite'
import AnunturiSideBar from '../components/AnunturiSideBar'

export default defineComponent({
  name: 'Anunturi',
    data() {
      return {
        anunturi: "",
        numeCv: "",
        emailCv: "",
        telefonCv: "",
        mesajCv: "",
        file: "",
        Message: "",
        reqSuccess: false,
        token: ""
      }
    },
  components: {HeaderWebsite,FooterWebsite,AnunturiSideBar},
  methods: {
    getAnunturi() {
            axios.post(php_url.php_url,JSON.stringify({action:"getAnunturi"})).then((res) => {
             
              this.anunturi = res.data
              // Object.values(getData).forEach(val => {
              //   console.log(val.titlu);
              // });
 
                })
            },
    handleFileUpload() {
        this.file = this.$refs.file.files[0]
    },
    trimiteEmailCV() {
        if(!this.numeCv) {
            this.Message = "Completați numele și prenumele!"
        } else if(!this.emailCv) {
          this.Message = "Completați adresa de email!"
        } else if(!this.telefonCv) {
          this.Message = "Completați numărul de telefon!"
        } else if(!this.mesajCv) {
            this.Message = "Completați mesajul!"
        } else {
          this.recaptchaToken().then(() => {
            let formData = new FormData();

            formData.append('file',this.file)
            formData.append('action',"trimiteEmailCV")
            formData.append('nume_cv',this.numeCv)
            formData.append('email_cv',this.emailCv)
            formData.append('telefon_cv',this.telefonCv)
            formData.append('mesaj_cv',this.mesajCv)
            formData.append('token',this.token)

            axios({
                url: php_url.php_url,
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            }).then((res) => {
                // console.log(res.data);

                if(res.data == 1) {
                  this.reqSuccess = true
                  this.Message = "Emailul a fost trimis cu succes!"
                } else {
                  this.Message = "Eroare necunoscută!"
                }
            })

          })
        }
    },
    async recaptchaToken() {
        const recaptcha =  await load('6LcvvBMjAAAAAA3airCHauoCe3TGQhvrdmD-n_oG',{autoHideBadge: false})
        recaptcha.showBadge()
        let token = await recaptcha.execute('login')

        this.token = token

        setTimeout(function() {
          recaptcha.hideBadge()
        }.bind(this),3000)

      },
    // ckRouteName() {
      // this.$emit('currentRoute', this.currentRouteName)
      // console.log(this.currentRouteName)
    // }
      
    },
    mounted() {
      this.getAnunturi()
    }
 
  // emits:["currentRoute"]
})
</script>

<style>

  #news-content {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    padding: 20px;
    font-family: sans-serif;
  }


  #news-content hr {
    
    margin-top: 8px;
  }

  #news-content #sectiune-cv {
    height: calc(100vh - 212px);
    overflow-y: auto;
  }
  

  #news-content img{
    cursor: pointer;
    width: 40%;
    border-radius: 22px;
    display: flex;
    float: left;
    padding-right: 8px;
    min-height: 210px;
  }

  #news-content p {
    text-align: justify;
    /* text-indent: 30px; */
    line-height: 26px;
    font-size: 19px;
    min-height: 259px;
  }

  #news-content h4 {
    text-align: left;
  }

  #news-content #anunturi {
    height: calc(100vh - 212px);
    overflow-y: auto;
    padding-right: 30px;
  }

  #news-content #formular-cv {
    border: solid 2px #3d5ac1;
    border-radius: 5px;
    width: 100%;
    display: grid;
    box-shadow: 5px 6px 7px;
    background-color: #3d5ac1;
    max-width: 500px;
    margin: 0 auto;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    
  }

  #news-content #formular-cv input {
      margin-bottom: 8px;
      margin-top: 8px;
      border-radius: 5px;
      height: 32px;
      font-size: 14px;
      padding: 7px;
  }

  #news-content #formular-cv textarea {
    border-radius: 5px;
    padding: 7px;
  }


  #news-content #formular-cv hr {
    margin: 0 auto;
  }

  #news-content #formular-cv h3 {
    color: #ffffff;
    text-shadow: 3px 4px 5px #222222;
  }
    
  @media only screen and (max-width: 991px) {
    div#news-content {
      margin: 0 auto;
      border: unset;
    }

    #news-content #sectiune-cv {
      height: unset;
    }
    

    #news-content #formular-cv {
      margin-left: 0;
    }

    #news-content {
    height: unset;
    }

    #news-content #anunturi {
      padding-right: 5px;
      height: unset;
      overflow-y: hidden;
    }

    body {
      overflow-y: auto;
    }
  }

</style>