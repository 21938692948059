<template>
  <HeaderWebsite />
    <div style="--bs-gutter-x: 6px;" class="row w-100 transition">
      <div class="col-sm-5 col-md-3 col-lg-2">
          <AnunturiSideBar />
      </div>
       <div class="col-sm-7 col-md-9 col-lg-10">
        <div id="consiliere-content">
          <h4><fa class="me-2" icon="person-shelter" size="1x"/><b>CONSILIERE ȘI ASISTENȚĂ</b></h4>
          <hr>
          <div class="row">
            <div  class="d-flex gap-4 w-75 mx-auto mb-3">
              <input type="text" placeholder="Nume și prenume" id="nume_prenume" class="form-control" v-model="nume">
              <input type="text" placeholder="E-mail" id="email" class="form-control" v-model="email">
              <input type="number" placeholder="Telefon" id="telefon" class="form-control" v-model="telefon">
            </div>
                  
            <div style="border-right: 2px solid gray;" class="col-sm-12 col-lg-6" id="formular-asis-soc">
              <h5 class="fw-bold text-start text-success mt-4">Formular Asistență Socială</h5>
              <hr style="width:300px;">
              
              <div style="flex-flow: wrap;" class="row mb-2" v-for="chestAsis in chestAsisSoc" :key="chestAsis.text">
                <h6 v-if="chestAsis.tip == 'titlu'" class="text-start fw-bold my-3">{{chestAsis.text}}</h6>

                <div v-if="chestAsis.tip == 'continut'" style="text-align:justify;" class="col-lg-9 d-flex align-items-center text-justify my-1">
                  {{chestAsis.text}}
                </div>
                <div style="flex: 1 1 100%;" v-else-if="chestAsis.tip == 'textarea'"  class="col-lg-9 d-flex flex-column align-items-center my-1 ">
                  <label for="" class="my-1 align-self-start">{{chestAsis.text}}</label>
                  <textarea @change="addToAsisChest(chestAsis.name,asisModel[chestAsis.name])" class="w-75 align-self-start" rows="5" v-model="asisModel[chestAsis.name]"></textarea>
                </div>
                <div v-if="chestAsis.tip == 'continut'" style="place-content: center;" class="col-lg-3 d-flex align-items-center gap-4">
                  <label class="d-flex text-info fw-bold">DA
                    <input @click="addToAsisChest(chestAsis.name,'1')" style="width: 20px;height:20px;" type="radio" :name="chestAsis.name" class="ms-1" />
                  </label>
                  <label class="d-flex text-danger fw-bold">NU
                    <input @click="addToAsisChest(chestAsis.name,'0')" style="width: 20px;height:20px;" type="radio" :name="chestAsis.name" class="ms-1"/>
                  </label>
                </div>
                <!-- <div v-else-if="chestAsis.tip == 'textarea'"  class="col-lg-3 d-flex align-items-center gap-4">
                </div> -->
              </div>

              <div class="row mb-2">
                <div style="text-align:justify;" class="col-lg-9 d-flex align-items-center text-start my-1 fw-bold">
                  5. Aveți vreo promblemă particulară de natură socială?
                </div>

                <div style="place-content: center;" class="col-lg-3 d-flex align-items-center gap-4">
                  <label class="text-info fw-bold">DA
                    <input @change="addToAsisChest('a5_1','1')" style="width: 20px;height:20px;" type="radio" class="ms-1" name="a5_1"/>
                  </label>
                  <label class="text-danger fw-bold">NU
                    <input @change="addToAsisChest('a5_1','0')" style="width: 20px;height:20px;" type="radio" class="ms-1" name="a5_1"/>
                  </label>
                </div>
                
              </div>

              <div class="mb-2 d-flex flex-column">
                <label for="" class="my-1 align-self-start fw-bold">Descrieți problema</label>
                <textarea @change="addToAsisChest('a5_2',asisModel['a5_2'])" class="w-75 align-self-start" rows="5"  v-model="asisModel['a5_2']"></textarea>
                
              </div>
             
            </div>
            
            <div class="col-sm-12 col-lg-6" id="formular-consiliere">
              <h5 class="fw-bold text-start text-success mt-4">Formular Consiliere</h5>
              <hr style="width:300px;">
              
              <div style="flex-flow: wrap;" class="row mb-2" v-for="chestCons in chestConsiliere" :key="chestCons.text">
                <h6 v-if="chestCons.tip == 'titlu'" class="text-start fw-bold my-3">{{chestCons.text}}</h6>

                <div v-if="chestCons.tip == 'continut'" style="text-align:justify;" class="col-lg-9 d-flex align-items-center text-justify my-1">
                  {{chestCons.text}}
                </div>
                <div v-if="chestCons.tip == 'sub-continut'" style="text-align:justify;" class="col-lg-9 d-flex align-items-center text-justify my-1 ps-5">
                  {{chestCons.text}}
                </div>
                <div style="flex: 1 1 100%;" v-else-if="chestCons.tip == 'textarea'"  class="col-lg-9 d-flex flex-column align-items-center my-1 ">
                <label for="" class="my-1 align-self-start">{{chestCons.text}}</label>
                  <textarea @change="addToConsChest(chestCons.name,consilModel[chestCons.name])" class="w-75 align-self-start" rows="5" v-model="consilModel[chestCons.name]"></textarea>
                </div>
                <div v-if="chestCons.tip == 'continut' || chestCons.tip == 'sub-continut'" style="place-content: center;" class="col-lg-3 d-flex align-items-center gap-4">
                  <label v-if="chestCons.tip == 'continut' && chestCons.da" class="text-info fw-bold">{{chestCons.da}}
                    <input  @click="addToConsChest(chestCons.name,'1')" type="radio"  style="width: 20px;height:20px;" class="ms-1" :name="chestCons.name"/>
                  </label>
                  <label v-if="chestCons.tip == 'continut' && chestCons.nu" class="text-danger fw-bold">{{chestCons.nu}}
                    <input @click="addToConsChest(chestCons.name,'0')" type="radio"  style="width: 20px;height:20px;" class="ms-1"  :name="chestCons.name"/>
                  </label>
                 
                  <div style="place-content: start;" v-if="chestCons.tip == 'sub-continut'">
                  
                    <input @change="addToConsChest(chestCons.name,consilModel[chestCons.name])" type="checkbox" style="margin-right:38px;" v-model="consilModel[chestCons.name]"/>
                   
                  </div>
                 
                </div>
                <div v-else-if="chestCons.tip == 'textarea'" class="col-lg-3 d-flex align-items-center gap-4">
                </div>
              </div>

            </div>
          </div>
          <hr>

          <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
            <span v-html="Message"></span>
          </div>
          <button @click="saveChestionar" style="width:200px;" class="btn btn-success d-flex mt-3 justify-content-center mx-auto" >Trimite informații</button>

        </div>
        
      </div>
    </div>
  
<FooterWebsite />
</template>

<script>
  import { defineComponent } from 'vue';

  import axios from "axios"
  import php_url from "../main.js"

  import { load } from 'recaptcha-v3'

  import HeaderWebsite from '../components/HeaderWebsite'
  import FooterWebsite from '../components/FooterWebsite'
  import AnunturiSideBar from '../components/AnunturiSideBar'

  const chestAsisSoc= [
            {text: '1. SITUAȚIA ECONOMICĂ', da: '',nu: '',tip: 'titlu',name: ''},
            {text: 'a. Familia se află în situație de sărăcie?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a1_a'},
            {text: 'b. Unul sau ambii părinți sunt fără ocupație sau în șomaj?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a1_b'},
            {text: 'c. Familia este beneficiară de beneficii sociale?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a1_c'},
            {text: '2. SITUAȚIA SOCIALĂ', da: '',nu: '',tip: 'titlu',name: ''},
            {text: 'a. În familie există o mamă minoră sau o minoră gravidă?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_a'},
            {text: 'b. Familia este monoparentală?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_b'},
            {text: 'c. Unul sau ambii părinți sunt plecați la muncă în țară sau străinătate?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_c'},
            {text: 'd. Ambii părinți sunt decedați, necunoscuți, decăzuți din exercițiul drepturilor părintești sau li s-a aplicat pedeapsa penală a interzicerii drepturilor părintești, au fost puși sub interdicție judecătorească, dispăruți ori declarați judecătorește morți și nu a fost instituită tutela sau, după caz, o măsură de protecție specială?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_d'},
            {text: 'e. Familia are unul sau mai mulți copii care au revenit în țara de origine după o experiență de migrație de peste un an?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_e'},
            {text: 'f. Familia are unul sau mai mulți copii în sistemul de protecție socială?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_f'},
            {text: 'g. Familia are unul sau mai mulți copii reintegrați din sistemul de protecție specială?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_g'},
            {text: 'h. Familia are membrii cu dizabilități senzoriale, neurologice ori intelectuale care le limitează semnificativ calitatea vieții și participarea lor la viața socială?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_h'},
            {text: 'i. Cel puțin un membru al familiei (inclusiv adult) nu are acte de stare civilă?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_i'},
            {text: 'j. Familia are unul sau mai mulți membri condamnați la o pedeapsă privată de libertate?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_j'},
            {text: 'k. Familia se află în orice alte situații care pot indica o vulnerabilitate?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a2_k'},
            {text: 'Vă rugăm prezentați care altă situație (dacă e cazul)', da: '',nu: '',tip: 'textarea',name: 'a2_l'},
            
            {text: '3. NIVELUL DE EDUCAȚIE', da: '',nu: '',tip: 'titlu',name: ''},
            {text: 'a. Unul sau ambii părinți sunt analfabeți?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_a'},
            {text: 'b. Familia are unul sau mai mulți copii cu vârstă școlară care nu frecventează cursurile unei forme de învățământ obligatoriu?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_b'},
            {text: 'c. Familia are unul sau mai mulți copii care au abandonat timpuriu școala?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_c'},
            {text: 'd. Familia are unul sau mai mulți copii cu frecvență școlară redusă sau repetenție?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_d'},
            {text: 'e. Familia are unul sau mai mulți copii cu performanțe școlare slabe(corigențe etc)?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_e'},
            {text: 'f. Familia are unul sau mai mulți copii cu istoric de sancționare în cadrul școlii (exmatriculare, nota scăzută la purtare etc)?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_f'},
            {text: 'g. Familia are număr mare de copii antepreșcolari/preșcolari/școlari în familie?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_g'},
            {text: 'h. Familia are unul sau mai mulți copii cu cerințe educaționale speciale?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_h'},
            {text: 'i. Familia se confruntă cu altă situație care poate afecta dreptul copilului la educație?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a3_i'},
            {text: 'Vă rugăm precizați care alte tipuri de probleme din sfera educației (dacă e cazul)', da: '',nu: '',tip: 'textarea',name: 'a3_j'},

            {text: '4. CONDIȚII DE LOCUIT', da: '',nu: '',tip: 'titlu',name: ''},
            {text: 'a. Familia ocupă abuziv, fară drept de ședere anumite spații de locuit sau inclusiv spații construite ilegal?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a4_a'},
            {text: 'b. Familia locuiește în condiții improprii, respectiv locuința este în stare avansată de degradare sau este improvizată în spații care nu sunt destinate acestui scop magazii, case de apă, elemente de canalizare, constructii în stare avansată de degradare etc?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a4_b'},
            {text: 'c. Familia nu dispune de un spațiu locuibil suficient raportat la numărul de persoane; locuința este supraaglomerată?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a4_c'},
            {text: 'd. Familia nu are acces la utilități, în mod special la sursă de apă, electricitate și încălzire?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a4_d'},
            {text: 'e. Familia nu dispune de dotări minime necesare pregătirii hranei, asigurării încălzirii și de mobilier de strictă necesitate?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a4_e'},
            {text: 'f. Locuința nu este întreținută, lipsa igienei?', da: 'DA',nu: 'NU',tip: 'continut',name: 'a4_f'},
            {text: 'Vă rugăm precizați care alte tipuri de probleme din sfera condițiilor de locuit (dacă e cazul)', da: '',nu: '',tip: 'textarea',name: 'a4_g'},
            
          ]

  const chestConsiliere= [
            {text: '1.	Considerați utilă participarea dvs. la un curs de formare profesională oferit în cadrul proiectului?', da: 'DA',nu: 'NU',tip: 'continut',name: 'c1'},
            {text: '2.	La ce curs ați dori să participați? ', da: '',nu: '',tip: 'textarea',name: 'c2'},

            {text: '3.	Ce așteptări aveți în urma calificării pentru care optați? ', da: '',nu: '',tip: 'continut',name: ''},
            {text: 'Să ocup un loc de muncă corespunzător calificării', da: 'DA',nu: 'NU',tip: 'sub-continut',name: 'c3_1'},
            {text: 'Să mi se ofere recunoașterea meritelor', da: 'DA',nu: 'NU',tip: 'sub-continut',name: 'c3_2'},
            {text: 'Creșterea salariului datorat calificării aduse suplimentar', da: 'DA',nu: 'NU',tip: 'sub-continut',name: 'c3_3'},
            {text: 'Să-mi ofere posibilitatea de a promova', da: 'DA',nu: 'NU',tip: 'sub-continut',name: 'c3_4'},

            {text: '4.	Considerați că sunteți/ați putea fi un angajat bun?', da: 'DA',nu: 'Nu știu',tip: 'continut',name: 'c4'},

            {text: '5.	Care credeți că sunt motivele pentru care ocupați foarte greu un loc de muncă?', da: '',nu: '',tip: 'continut',name: ''},
            {text: 'Lipsa locurilor de muncă disponibile', da: '',nu: '',tip: 'sub-continut',name: 'c5_1'},
            {text: 'Lipsa unei calificări/studii', da: '',nu: '',tip: 'sub-continut',name: 'c5_2'},
            {text: 'Lipsa investițiilor', da: '',nu: '',tip: 'sub-continut',name: 'c5_3'},
            {text: 'Falimentul unitaților economice', da: '',nu: '',tip: 'sub-continut',name: 'c5_4'},

            {text: '6.	Până acum ați ocupat locuri de muncă în domeniul în care v-ați dorit?', da: 'DA',nu: 'NU',tip: 'continut',name: 'c6'},

            {text: '7.	Aveți planuri legate de viața dvs. profesională în viitorul apropiat?', da: '',nu: '',tip: 'continut',name: ''},
            {text: 'Da,  vreau să mă angajez', da: '',nu: '',tip: 'sub-continut',name: 'c7_1'},
            {text: 'Da, vreau să îmi schimb locul de muncă', da: '',nu: '',tip: 'sub-continut',name: 'c7_2'},
            {text: 'Da, vreau să obțin o mărire de salariu', da: '',nu: '',tip: 'sub-continut',name: 'c7_3'},
            {text: 'Da, vreau să obțin o avansare', da: '',nu: '',tip: 'sub-continut',name: 'c7_4'},
            {text: 'Da, vreau să îmi obțin și alte calificări', da: '',nu: '',tip: 'sub-continut',name: 'c7_5'},
            {text: 'Da, vreau să îmi continui studiile', da: '',nu: '',tip: 'sub-continut',name: 'c7_6'},
            {text: 'Nu vreau să fac nimic în acest sens', da: '',nu: '',tip: 'sub-continut',name: 'c7_7'},
            {text: 'Vreau să fiu propriul angajat și să îmi deschid o afacere', da: '',nu: '',tip: 'sub-continut',name: 'c7_8'},

            {text: '8.	Ți s-a întâmplat vreodată să te gândești la însușirile tale?', da: 'DA',nu: 'NU',tip: 'continut',name: 'c8'},

            {text: '9.	Care crezi că sunt cele mai importante calități ale tale?', da: '',nu: '',tip: 'textarea',name: 'c9'},
            {text: '10. Ce calități apreciază cei din jurul tău la tine?', da: '',nu: '',tip: 'textarea',name: 'c10'},
            {text: '11. Cu cine ai vrea să semeni? De ce?', da: '',nu: '',tip: 'textarea',name: 'c11'},
            {text: '12. Care crezi că sunt punctele tale forte?', da: '',nu: '',tip: 'textarea',name: 'c12'},
            {text: '13. Care crezi că sunt punctele tale slabe?', da: '',nu: '',tip: 'textarea',name: 'c13'},
          ]

  export default defineComponent({
    name: 'Consiliere',
      data() {
        return {
          nume: "",
          email: "",
          telefon: "",
          chestAsisSoc: chestAsisSoc,
          chestConsiliere: chestConsiliere,
          asisModel: [],
          consilModel: [],
          asisChest: {},
          consilChest: {},
          Message: "",
          reqSuccess: false,
          token: ""
        }
      },
    components: {HeaderWebsite,FooterWebsite,AnunturiSideBar},
    methods: {
      addToAsisChest(key,value) {
        this.asisChest[key] = value
     
      },
      addToConsChest(key,value) {
        if(value == true) {
          value = 1
        } else if(value == false) {
          value = 0
        }
        this.consilChest[key] = value
      },
      saveChestionar() {
        console.log(this.asisChest+" - "+this.consilChest);
          if(!this.nume) {
            this.reqSuccess = false
            this.Message = "Completați numele!"
          } else if(!this.email) {
            this.reqSuccess = false
            this.Message = "Completați adresa de email!"
          } else if(!this.telefon) {
            this.reqSuccess = false
            this.Message = "Completați numărul de telefon!"
          } else if(this.asisChest.length == 0) {
            this.reqSuccess = false
            this.Message = "Completați formularul Asistență socială!"
          } else if(this.consilChest.length == 0) { 
            this.reqSuccess = false
            this.Message = "Completați formularul Consiliere!"
          } else {
            this.recaptchaToken().then(() => {
              
              let asisChest = JSON.stringify(this.asisChest)
              let consilChest = JSON.stringify(this.consilChest)

              axios.post(php_url.php_url,JSON.stringify({action:"saveChestionar",nume:this.nume,email:this.email,telefon:this.telefon,asis_chest:asisChest,consil_chest:consilChest,token:this.token})).then((res) => {
              
                if(res.data == 1) {
                  this.reqSuccess= true
                  this.Message = "Chestionarul a fost trimis cu succes!"
                } else {
                  this.reqSuccess = false
                  this.Message = "Eroare necunoscută!"
                }
              
              })
            })
          }

      },
      async recaptchaToken() {
        const recaptcha =  await load('6LcvvBMjAAAAAA3airCHauoCe3TGQhvrdmD-n_oG',{autoHideBadge: false})
        recaptcha.showBadge()
        let token = await recaptcha.execute('login')

        this.token = token

        setTimeout(function() {
          recaptcha.hideBadge()
        }.bind(this),3000)

      },
    },
  })
</script>

<style>

  #consiliere-content {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    overflow-y: auto;
    padding: 20px;
    font-family: sans-serif;
  }

  #consiliere-content input[type="text"],#consiliere-content input[type="number"]{
    margin-bottom: 8px;
    margin-top: 8px;
    border-radius: 3px;
    height: 32px;
    font-size: 14px;
    padding: 7px;
  
  }


  #consiliere-content input[type='checkbox']  {
    transform: scale(1.4);
  }

  #consiliere-content hr {
    margin-top: 8px;
  }

  #consiliere-content img{
    width: 105px;
    display: flex;
    float: left;
    padding-right: 8px;
  }

  #consiliere-content p {
    text-align: justify;
    text-indent: 30px;
    line-height: 26px;
    font-size: 19px;
  }

  #consiliere-content h4 {
    text-align: left;
  }

  @media only screen and (max-width: 1381px) {
    #consiliere-content #formular-asis-soc input {
    margin-left: 0;
    }
  }
    
  @media only screen and (max-width: 991px) {
    div#consiliere-content {
      margin-left: 7px;
    }
  }

</style>