<template>
  <h4 style="text-align:left;"><fa class="me-2 " icon="address-card" size="1x"/><b>CORESPONDENȚĂ</b></h4>
  <hr class="my-0">
    <div class="row w-100 transition" id="corespondentaAdmin">
      
      <div class="col-sm-12 col-md-4 col-lg-3">
        <input type="checkbox" v-model="selectAll" @click="selectAllOptions()" style="transform: scale(1.3);margin-bottom:15px;"/> <span style="margin-left:5px;"><b>Selectează toți utilizatorii</b></span>
        <select v-model="userSelect" class="form-select" id="user-select" multiple>
            <option v-for="uti in utiList" :key="uti.id" :value="uti.id" >{{uti.nume_prenume}}</option>
        </select>
      </div>

      <div class="p-3 col-sm-12 col-md-8 col-lg-9" id="formular-content">
          <h3><b>Formular</b></h3>
          <div class="text-info">Selectați utilizatorii cărora doriți să le trimiteți email!</div>
          <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
            {{Message}}
          </div>
        
        <input class="form-control" type="text" placeholder="Subiect" v-model="subiect">
        <textarea class="my-2 form-control" rows="10" placeholder="Mesaj" v-model="mesaj"></textarea>
        <div class="my-3">
          <button @click="corespondentaUseri()" class="btn btn-success">Trimite</button>
          <button @click="resetFields()" class="btn btn-danger ms-5">Resetează</button>
        </div>
      </div>
      
    </div>
   
  
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios"
import php_url from "../../main.js"

export default defineComponent({
  name: 'CorespondentaUseri',
    data() {
      return {
        selectAll: false,
        loggedUserId: "",
        loggedUserAccess: "",
        utiList: "",
        userSelect: [],
        subiect: "",
        mesaj: "",
        Message: "",
        reqSuccess: false,
      }
    },
  components: {},
  inheritAttrs: false,
  methods: {
    selectAllOptions() {
      if(!this.selectAll) {
        this.userSelect = this.allOptions
      } else {
        this.userSelect = []
      }
     
    },

    getUtiList() {    
      
      axios.post(php_url.php_url,JSON.stringify({action:"getUtiList"})).then((res) => {
          this.utiList = res.data.filter(uti => uti.acces != 1)   
      })
     
    },
    ckAccess() {
        axios.post(php_url.php_url,JSON.stringify({action:"ckAccess"})).then((res) => {
                this.loggedUserAccess = res.data.access
                this.loggedUserId = res.data.idCurrUti
                
            })
    },
    corespondentaUseri() {

      if(this.userSelect.length == 0) {
        this.reqSuccess = false
        this.Message = "Selectați cel puțin un utilizator!"
      } else if(!this.subiect) { 
        this.reqSuccess = false
        this.Message = "Completați subiectul formularului!"
      } else if(!this.mesaj) { 
        this.reqSuccess = false
        this.Message = "Completați mesajul formularului!"
      } else {
       
        axios.post(php_url.php_url,JSON.stringify({action:"corespondentaUseri",users:this.userSelect,subiect:this.subiect,mesaj:this.mesaj})).then((res) => {
        
          if(res.data == 1) {
            this.reqSuccess= true
            this.Message = "Mesajul a fost trimis cu succes!"
              this.getUtiList()
              this.userSelect = []
              this.resetFields() 
          } else {
            this.reqSuccess = false
            this.Message = "Eroare necunoscută!"
          }
        
        })
      }

    },
    resetFields() {
      this.subiect = ""
      this.mesaj = ""
    }
  },
  computed: {
    allOptions() {
      this.getUtiList()
      return this.utiList.map(uti => uti.id)
    }
  },
  mounted() {
      this.getUtiList()
      this.ckAccess()
  },
})
</script>

<style>

    #corespondentaAdmin #user-select {
        height: calc(100vh - 281px);
    }

    #corespondentaAdmin div#select-list {
        /* border-right: 2px solid gray; */
        padding-right: 21px;
    }

    #corespondentaAdmin #select-list select {
        cursor: pointer;
        font-size: 18px;
    }

  #corespondentaAdmin {
    /* border: 2px solid #395e8d; */
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: calc(100vh - 178px);
    overflow-y: auto;
    padding: 20px;
    font-family: sans-serif;
  }

  #corespondentaAdmin hr {
    width:200px;
    margin-top: 8px;
  }

  #corespondentaAdmin p {
    text-align: justify;
    line-height: 26px;
    font-size: 19px;
  }

  #corespondentaAdmin #formular-content {
    border: solid 2px #2f3d7e;
    border-radius: 5px;
    width: 100%;
    display: grid;
    box-shadow: 5px 6px 7px;
    background-color: #1d2d5a;
    max-width: 500px;
    margin: 0 auto;
    height: 556px;
    align-self: center;
  }

  #corespondentaAdmin #formular-content input {
      margin-bottom: 8px;
      margin-top: 8px;
      border-radius: 5px;
      height: 32px;
      font-size: 16px;
      padding: 7px;
      align-self: center;
  }

    #corespondentaAdmin #formular-content textarea {
      border-radius: 5px;
      padding: 7px;
      font-size: 16px;
      align-self: center;
    }


  #corespondentaAdmin #formular-content hr {
    margin: 0 auto;
  }

  #corespondentaAdmin #formular-content h3 {
    color: #ffffff;
    text-shadow: 3px 4px 5px #2080d3;
    align-self: center;
  }
  
    
  @media only screen and (max-width: 991px) {
    div#corespondentaAdmin {
      margin-left: 7px;
    }

    #corespondentaAdmin #formular-content {
      margin-left: 0;
    }
  }

</style>