<template>
    <div id="web-sidebar">
            <div class="s-anunturi-header"><fa class="me-2 fa-fade" icon="bullhorn" size="lg" /> Anunțuri</div>
            <div class="s-anunturi-content">
              <router-link v-for="(anunt, index) in anunturi" :key="anunt.id" to="/anunturi" class="text-decoration-none text-light">
                <h5 class="mt-2">{{anunt.titlu}}</h5>
                <hr style="width: 70%;margin: 10px auto;">
                <p><span v-html='anunt.continut.substring(0,150)+" ..."'></span></p>
                <hr v-if="index != anunturi.length - 1" class="my-3">
              </router-link>
            </div>
          </div>
</template>


<script>
    import { defineComponent } from 'vue';
    import axios from "axios"
    import php_url from "../main.js"

    export default defineComponent({
        name: 'AnunturiSideBar',
        data() {
            return {
                anunturi: ""
            }
        },
        methods: {
            getAnunturi() {
                axios.post(php_url.php_url,JSON.stringify({action:"getAnunturi"})).then((res) => {
                this.anunturi = res.data
                
                })
            },
        },
        mounted() {
            this.getAnunturi()
        }
    })

</script>


<style>
    div#web-sidebar {
        background-color: #193a85;
        height: 99%;
        margin-left: 6px;
        margin-top: 3px;
        margin-right: 6px;
        border-radius: 5px;
        padding: 9px;
        color: white;
        box-shadow: 6px 5px 8px #3951a9f5;
        height: calc(100vh - 183px);
    }

   

    div#web-sidebar div.s-anunturi-header {
        height: 54px;
        background: linear-gradient(45deg, #ffffff, #1b7bdf);
        border-radius: 5px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-shadow: 2px 3px 4px black;
        font-size: 22px;
    }

    .s-anunturi-header svg {
        --fa-animation-duration: 2s;
        filter: drop-shadow(3px 5px 2px gray);
        color: #5166b1;
    }

    div.s-anunturi-content {
        height: calc(100vh - 265px);
        overflow-y: auto;
        border: 1px solid white;
        border-radius: 5px;
        margin-top: 11px;
        padding: 4px;
    }

    div.s-anunturi-content a:hover {
        color: #99d7e5!important;
    }

    div.s-anunturi-content h5{
        text-shadow: 5px 6px 5px black;
    }

    div.s-anunturi-content p{
        padding: 0 9px;
    }

    @media only screen and (max-width: 515px) {
        div#web-sidebar {
            display: none;
        }
     }
</style>