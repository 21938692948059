<template>
    <ConfirmDialog :show="showDialog"
    :okButton="da"
    :noButton="nu"
    :cancel="cancel"
    :confirm="confirm"
    title="Atenție!"
    description="Doriți ștergerea activității?" />

    <div id="activit-list-admin" class="transition">
         
        <h4><fa class="me-2 " icon="bullhorn" size="1x"/><b>ACTIVITĂȚI</b></h4>
        <hr>

        <div v-for="activit in activitati" :key="activit.id" class="text-start transitionFast">
            <button ref="edit" @click="editActivit(activit.id)" class="btn btn-success me-3">Editare</button>
            <button ref="sterge"  @click="callDialog(activit.id)" class="btn btn-danger">Șterge</button>

            <div style="margin-top: -28px;">
                <h5 class="fw-bold text-center">{{activit.titlu}}</h5>
                <hr class="mx-auto" style="width:200px;">
            </div>
            
            <img :src="activit.cale_img" title="Activitate" class="">
            <p><span v-html='activit.continut'></span></p>
            <hr>
        </div>
        
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import axios from "axios"
    import php_url from "../../main.js"

    import ConfirmDialog from '../dialogs/ConfirmDialog'


    export default defineComponent({
        components: { ConfirmDialog },
        emits: ["editActivit"],
        inheritAttrs: false,
        name: 'ActivitAdmin',
        data() {
            return {
                activitati: "",
                showDialog: false,
                da: "DA",
                nu: "NU",
                idActivit: ""
            }
        },
        methods: {
            getActivit() {
                    axios.post(php_url.php_url,JSON.stringify({action:"getActivitati"})).then((res) => {
                    // console.log(res.data);
                    this.activitati = res.data
        
                        })
                    },
            callDialog(activitId) {
                this.idActivit = activitId
                this.showDialog = true
            },

            cancel() {
                // console.log('cancel');
                this.showDialog = false;
            },
            confirm() {
                
                this.showDialog = false;

                // STERGE ACTIVITATE

                if(this.idActivit) {
                    axios.post(php_url.php_url,JSON.stringify({action:"stergeActivitate",id_activit:this.idActivit})).then((res) => {
                      
                        if(res.data.code == 1) {
                            this.getActivit()
                        } 
                    }) 
                }   
            },

            editActivit(activitId) {
                this.idActivit = activitId
                this.$emit('editActivit', this.idActivit)
            }
        },
        mounted() {
            this.getActivit()
        }
        
    })
</script>

<style>
    #activit-list-admin {
        /* border: 2px solid #395e8d; */
        border-radius: 5px;
        margin-bottom: 3px;
        height: calc(100vh - 197px);
        overflow-y: auto;
        padding: 5px 20px;

        font-family: sans-serif;
    }


    #activit-list-admin hr {
        margin-top: 8px;
    }

    #activit-list-admin img{
        width: 15%;
        border-radius: 22px;
        cursor: pointer;
        display: flex;
        float: left;
        padding-right: 8px;
    }

    #activit-list-admin p {
        text-align: justify;
        
        line-height: 26px;
        font-size: 19px;
        margin-top: 10px;
    }

    #activit-list-admin h4 {
        text-align: left;
    }
        
    @media only screen and (max-width: 991px) {
        div#activit-list-admin {
        margin-left: 7px;
        }
    }
</style>